import React, { useState, createContext } from "react";

export const FilmsContext = createContext();

export const FilmsContextProvider = (props) => {
  const [films, setDataArray] = useState([]);
  const id_pagina = useState("");

  const addRestaurants = (film) => {
    setDataArray([...films, film]);
  };

  return (
    <FilmsContext.Provider
      value={{ films, setDataArray, id_pagina, addRestaurants }}
    >
      {props.children}
    </FilmsContext.Provider>
  );
};
