import React, { useContext, useEffect, useState } from "react";
import { FilmsContext } from "../context/FilmsContext";
import { useNavigate, useParams } from "react-router-dom";
import FilmFinder from "../apis/FilmFinder";
import Countdown from "./misc/Countdown";
import { CountdownProvider } from "../context/CountdownContext";
import "../../src/components/css/help.css";
import RatingStar from "./misc/RatingStar";
import "../../src/components/css/ButtonHelp.css";

const PaginaRisposte = (props) => {

  // settaggio (tbd)
  const minuti_iniziali = 2;

  const { id_sessione } = useParams();
  const { id_pagina } = useParams();
  const { userId } = useParams();
  const { closed } = useParams();

  const { films, setDataArray } = useContext(FilmsContext);

  const [cover, setCover] = useState("");
  //const [random, setRandomFilm] = useState("");
  const [idRisposta, setIdRisposta] = useState("");
  const [isPageClosed, setPageClosed] = useState(null);
  const [startTimeMinutes, setStartTimeMinutes] = useState(null);
  const [startTimeSeconds, setStartTimeSeconds] = useState(null);
  const [isQuizClosed, setQuizEnded] = useState(null);

  const [selectedButton, setSelectedButton] = useState(null);

  const [releaseDate, setReleaseDate] = useState("");
  const [punteggioTMDB, setPunteggioTMDB] = useState("");
  const [attore, setAttore] = useState("");
  const [personaggio, setPersonaggio] = useState("");
  const [imgAttore, setImgAttore] = useState("");

  const [idFilmErrati, setIdFilmErrati] = useState([]);

  const [utilizza5050, setUtilizza5050] = useState(false);
  const [utilizzaSwitch, setUtilizzaSwitch] = useState(false);
  const [utilizzaReleaseDate, setUtilizzaReleaseDate] = useState(false);
  const [utilizzaPunteggioTMDB, setUtilizzaPunteggioTMDB] = useState(false);
  const [utilizzaInfoAttore, setUtilizzaInfoAttore] = useState(false);
  
  const [numAiutiIniziali, setNumAiutiIniziali] = useState(0);
  const [risposteDate, setRisposteDate] = useState(0);

  const [categoria, setCategoria] = useState();
  const [allAiuti, setAllAiuti] = useState([]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // I mesi sono zero-indicizzati
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  function refreshStati() {
    setIdRisposta("")
    setIdRisposta("");
    setQuizEnded("");
    setSelectedButton(null)
    setIdFilmErrati([])
    setUtilizza5050(false)
    setUtilizzaSwitch(false)
    setUtilizzaPunteggioTMDB(false);
    setUtilizzaInfoAttore(false);
    setUtilizzaReleaseDate(false)
    setNumAiutiIniziali(0)
    setCategoria()
    setAllAiuti([]);
    setPunteggioTMDB("");
    setReleaseDate("");
    setImgAttore("");
    setAttore("");

  }

  function helpController(id_aiuto) {
    switch (id_aiuto) {
      case 1:
        refreshStati();
        usaAiuto5050();
        setUtilizza5050(true);
        break;
      case 2:
        refreshStati();
        usaAiutoSwitch();
        break;
      case 3:
        refreshStati();
        usaAiutoAnno();
        break;
      case 4:
        refreshStati();
        usaAiutoPunteggioTMDB();
        break;
      case 5:
        refreshStati();
        usaAiutoAttori();
        break;
      default:
        break;
    }
  }

  const usaAiutoSwitch = async () => {
    await FilmFinder.get(`/proc/film/usa_aiuto_switch/${id_sessione}/${id_pagina}`);

    setUtilizzaSwitch(true);

  }

  const usaAiutoAnno = async () => {
    const res = await FilmFinder.get(`/proc/film/usa_aiuto_data_uscita/${id_sessione}/${id_pagina}`);

    setReleaseDate(res.data.result.release_date);
    setUtilizzaReleaseDate(true);
  }

  const usaAiutoPunteggioTMDB = async () => {
    const res = await FilmFinder.get(`/proc/film/usa_aiuto_punteggio_tmdb/${id_sessione}/${id_pagina}`);

    setPunteggioTMDB(res.data.result.punteggio_tmdb);
    setUtilizzaPunteggioTMDB(true);

  }

  const usaAiutoAttori= async () => {
    const res = await FilmFinder.get(`/proc/film/usa_aiuto_film_attori/${id_sessione}/${id_pagina}`);

    //setIdAttore(res.data.result.id_attore_tmdb);
    setAttore(res.data.result.nome_attore);
    setPersonaggio(res.data.result.nome_personaggio);

    const resId = await fetch(`https://api.themoviedb.org/3/person/${res.data.result.id_attore_tmdb}?api_key=${process.env.REACT_APP_TMDB_API_KEY}`);
    const data = await resId.json();
    //console.log(data.profile_path);
    setImgAttore(data.profile_path);
    setUtilizzaInfoAttore(true);

  }

  const usaAiuto5050 = async () => {
      //console.log(films);
      await FilmFinder.delete(`/proc/eliminaaiuto/${id_sessione}`);
      setNumAiutiIniziali(numAiutiIniziali-1);

      const res = await FilmFinder.get(`/proc/film/usa_aiuto_5050/${id_sessione}/${id_pagina}`);

      setIdFilmErrati(res.data.result);
      setUtilizza5050(true);

  }

  const nascondiIdErrati = (id) => {
    // Controlla se l'id è presente in uno degli oggetti dell'array
    //console.log(idFilmErrati);

    // bisogna "pulire" i disabled
    if(!setUtilizza5050){
      return "";
    }

    if(id === "undefined") {return;}

    for (let i = 0; i < idFilmErrati.length; i++) {
      if (idFilmErrati[i].id_risposta_errata === id) {
        //console.log("errato");
        return "disabled";
      }
    }

    // Se l'id non è stato trovato in nessuno degli oggetti, restituisci l'id
    return id;
  };

  const goEndDude = async () => {
    navigate(`/quiz/${userId}/${id_sessione}/checkchiusura`);
  };

  //nel video è presente history, ma è deprecata
  let navigate = useNavigate();
  const token = localStorage.getItem('token');

  // sincronizzo il componente esterno con questo componente
  useEffect(() => {

    if (!token) {
      navigate("/login");
    }

    try {
      const fetchData = async () => {

        /* non è preciso
        const timer = parseInt(startTimeSeconds*1000*startTimeMinutes*2);
        
        if(startTimeSeconds > 0 && startTimeMinutes >= 0) {
          setTimeout(() => {
            navigate(`/quiz/${userId}/${id_sessione}/checkchiusura`);

          }, (timer));
        }
        */

        const startAiuti = await FilmFinder.get(`/proc/getnumeroaiuti/${id_sessione}`);
        setNumAiutiIniziali(startAiuti.data.result.numero_aiuti);

        const resAiuti = await FilmFinder.get(`/proc/get-all-aiuti/${id_sessione}`);
        setAllAiuti(resAiuti.data.result);

        const startTime = await FilmFinder.get(`/checktimer/${id_sessione}/${minuti_iniziali}`);
        
        const getCategoria = await FilmFinder.get(
          `/proc/getcategoria/${id_sessione}`
        );

        setCategoria(getCategoria.data.result.id_categoria);

        const getModalita = await FilmFinder.get(
          `/proc/getmodalita/${id_sessione}`
        );

        // setStartTime(300);
        setStartTimeMinutes(startTime.data.result.time_countdown.minutes);
        setStartTimeSeconds(startTime.data.result.time_countdown.seconds);

        // controllo se il quiz non è finito, nel caso disabilito risposte e tolgo il timer (e visualizzo "vedi Risposte")
        const isPageClosed = await FilmFinder.get(
          `/ispageclosed/${id_sessione}/${id_pagina}`
        );
        setPageClosed(isPageClosed.data.result.is_page_closed);

        // controllo se il quiz non è finito, nel caso disabilito risposte e tolgo il timer (e visualizzo "vedi Risposte")
        const isQuizClosed = await FilmFinder.get(
          `/isquizended/${id_sessione}`
        );
        setQuizEnded(isQuizClosed.data.result.check_sessione_conclusa);
        //console.log(getCategoria.data.result.id_categoria);

        // scelgo la soluzione del film per quella sessione
        const randomFilm = await FilmFinder.get(
          `/pickfilm/${id_sessione}/${id_pagina}/${userId}/${getCategoria.data.result.id_categoria}/${getModalita.data.result.id_modalita}`
        );

        //console.log(randomFilm.data.result);

        //setRandomFilm(randomFilm.data.result);

        // cerco la foto del film
        const cover = await FilmFinder.get(
          `/film/foto/${randomFilm.data.result.pick_random_film}`
        );
        setCover(cover.data.result.immagine);

        // cerco le risposte possibili
        // console.log(utilizza5050);

        const response = await FilmFinder.get(
          `/proc/cercarisposte/${id_sessione}/${id_pagina}/${userId}/${getCategoria.data.result.id_categoria}/${getModalita.data.result.id_modalita}`
        );
        setDataArray(response.data.result);

        const checkNRisposte = await FilmFinder.get(
          `/checknrisposte/${id_sessione}`
        );
        const nRisposte = parseInt(checkNRisposte.data.result.check_n_risposte);

        setRisposteDate(nRisposte);

        // cambiare con un flag di consultazione
        if (nRisposte >= 10 && closed!=="closed") {
          await FilmFinder.post(`/chiudisessione/${id_sessione}`);

          navigate(`/quiz/${userId}/${id_sessione}/checkchiusura`);
        }
      };

      fetchData();
    } catch (error) {
      console.log(error);
    }
  }, [startTimeMinutes, startTimeSeconds, closed, attore, releaseDate, punteggioTMDB, utilizza5050, utilizzaReleaseDate, 
      utilizzaInfoAttore, utilizzaPunteggioTMDB, utilizzaSwitch, idFilmErrati, 
      categoria, navigate, setDataArray, token, userId, id_pagina, isQuizClosed, id_sessione]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    await FilmFinder.get(
      `/proc/checkrisposta/${id_sessione}/${id_pagina}/${idRisposta}`
    );

    //check achievement
    /*
    await AdminApi.post(
      `/u/${userId}/${id_sessione}/checkachievements`
    );
    */

    if (id_pagina < 10) {
      const nextPageId = parseInt(id_pagina, 10) + 1;

      //console.log(closed);

      if(!closed) {
        navigate(`/quiz/${userId}/${id_sessione}/${categoria}/${nextPageId}`); }
      else {
        navigate(`/quiz/${userId}/${id_sessione}/${categoria}/${nextPageId}/closed`);
      }

    } else {
      
      if(!closed) { navigate(`/quiz/${userId}/${id_sessione}/checkchiusura`) }; }

    // Coccodì vanno riazzerati gli stati o viene fuori un casino

    refreshStati();

    if(!utilizza5050) {
      setIdFilmErrati([])
    };
    
  };

  const handleRightClick = (e) => {
    //e.preventDefault(); // Evita il menu contestuale del browser
    //console.log("Non imbrogliare!");
    // Altre azioni da eseguire al click destro
  };

  const backButton = (e) => {
    e.preventDefault(); // Evita il menu contestuale del browser

    if (id_pagina > 1) {
      let backPageId = parseInt(id_pagina, 10) - 1;

      if (!closed) {
        navigate(`/quiz/${userId}/${id_sessione}/${categoria}/${backPageId}`);
      }
      else {
        navigate(`/quiz/${userId}/${id_sessione}/${categoria}/${backPageId}/closed`)
      }
      
    }
  };

  const nextButton = (e) => {
    e.preventDefault(); // Evita il menu contestuale del browser

    if (id_pagina < 10) {
      const nextPageId = parseInt(id_pagina, 10) + 1;

      if(!closed) {
        navigate(`/quiz/${userId}/${id_sessione}/${categoria}/${nextPageId}`);
      }
      else {
        navigate(`/quiz/${userId}/${id_sessione}/${categoria}/${nextPageId}/closed`);
      }
      
    } else {
      navigate(`/quiz/${userId}/${id_sessione}/checkchiusura`);
    }

    //refreshStati();
    //setRandomFilm("");
  };

    // Costruisci dinamicamente la classe usando le condizioni fornite
    const getButtonClasses = (id) => {
      let classes = 'btn btn-block';
  
      // Aggiungi la classe basata su nascondiIdErrati
      classes += ` ${nascondiIdErrati(id)}`;
  
      // Aggiungi la classe btn-success o btn-primary
      classes += ` ${selectedButton === id ? 'btn-success' : 'btn-primary'}`;
  
      // Aggiungi la classe disabled se isPageClosed è 1 o il tempo è scaduto
      if (isPageClosed === 1 || (startTimeMinutes <= 0 && startTimeSeconds <= 0)) {
        classes += ' disabled';
      }
  
      return classes;
    };

  return (
    <div className="container" onContextMenu={handleRightClick}>
      {/* Barra di progresso */}
      <div className="progress">
        <div
          className="progress-bar"
          role="progressbar"
          style={{ width: `${(id_pagina / 10) * 100}%` }}
          aria-valuenow={id_pagina}
          aria-valuemin="0"
          aria-valuemax="10"
        >
          {id_pagina}/10
        </div>
      </div>
      <div className="progress">
        <div
          className="progress-bar progress-bar-striped progress-bar-animated bg-danger"
          role="progressbar"
          style={{ width: `${(risposteDate / 10) * 100}%` }}
          aria-valuenow={risposteDate}
          aria-valuemin="0"
          aria-valuemax="10"
        >
          {risposteDate}/10
        </div>
      </div>
      <div
        className="row justify-content-center"
        style={{ paddingBottom: "20px", paddingTop: "20px" }}
      >
        <div
          className="row justify-content-center"
          style={{ paddingBottom: "20px" }}
        >
          <div className="col-md-4">
            <button className="btn btn-primary btn-block" onClick={backButton}>
              Back
            </button>
          </div>
          <div className="col-md-4">
  {(() => {
    if (isQuizClosed === null || startTimeMinutes === null || startTimeSeconds === null || isPageClosed === null) {
      return null; // bugfix: Mostra nulla finché i dati non sono caricati
    }

    if (isQuizClosed === 1) {
      return (
        /*
        <CountdownProvider
          initialMinutes={0}
          initialSeconds={0}
        >
          <Countdown />
        </CountdownProvider>
        */
        <div className="btn btn-info btn-block" onClick={goEndDude}>
          Sessione quiz completata
        </div>
      );
    }

    if (isPageClosed === 1) {
      return (
        <CountdownProvider
          initialMinutes={startTimeMinutes}
          initialSeconds={startTimeSeconds}
        >
          <Countdown />
        </CountdownProvider>
      );
    }

    return (
      <CountdownProvider
        initialMinutes={startTimeMinutes}
        initialSeconds={startTimeSeconds}
      >
        <Countdown />
      </CountdownProvider>
    );
  })()}
</div>

          <div className="col-md-4">
            <button className="btn btn-primary btn-block" onClick={nextButton}>
              Next
            </button>
          </div>
          <div className="col-md-4">
            {(() => {
              switch (true) {
                case isQuizClosed === 1:
                    return (
                      /*
                      <div className="btn btn-info btn-block" onClick={goEndDude}>
                        Sessione quiz completata
                      </div>
                      */
                      null
                    );

                case startTimeMinutes <= 0 && startTimeSeconds <= 0:
                    return;
                
                case isPageClosed === 1 && isQuizClosed === 0:
                  return (
                    <div
                      className="btn btn-warning btn-block disabled"
                      disabled
                      style={{ marginLeft: '3px' }} 
                    >
                      Hai già risposto a questa domanda
                      
                    </div>
                  );
                  
                
                
                default:
                  return;
              }
            })()}
          </div>
        </div>
      </div>
      <div className="row justify-content-center">
        {/* Colonna a sinistra */}
        <div className="col-md-2">
          {allAiuti.map((aiuto, index) => (
            <div key={index}>
            <button
              className="help-button"
              onClick={() => helpController(aiuto.id_aiuto)}
            >
              {aiuto.descr_aiuto}
            </button>
            <div className=""></div>
          </div>
          ))}
        </div>
        {/* Colonna al centro */}
        <div className="col-md-8">
          <div className="question">
            <div
              style={{
                backgroundImage: `url(${cover})`,
                backgroundSize: "cover", // L'immagine viene ridimensionata per coprire l'intero contenitore
                backgroundPosition: "center", // Centra l'immagine
                width: "100%",
                height: "100%",
                padding: "30%",
              }}
              className="question-image text-center"
            ></div>
            <h2>A quale film appartiene questa immagine?</h2>
            <div className="parent-div">
              <div className="options row">
                {films.map((film) => (
                  <div key={film.id_risposta} className={`col-md-6`}>
                    <form onSubmit={handleSubmit}>
                      <button
                        value={film.id_risposta}
                        onClick={(e) => {
                          setIdRisposta(e.target.value);
                          setSelectedButton(film.id_risposta);
                        }}
                        className={getButtonClasses(film.id_risposta)}
                      >
                        {film.descr_film}
                      </button>
                    </form>
                    <p></p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        {/* Colonna a destra */}
        {(utilizzaReleaseDate || utilizzaPunteggioTMDB || utilizzaInfoAttore) && (
  <div className="col-md-2">
    <div className="card" style={{ height: "500px", width: "250px" }}>
      <div className="card-body">
        {/* Contenuto della card */}
        <h5 className="card-title">Suggerimento:</h5>
        { utilizzaReleaseDate && <div><p className="card-text">Anno di uscita: <p><b>{formatDate(releaseDate)}</b></p></p></div>}
        { utilizzaPunteggioTMDB && <div><p className="card-text">Punteggio TMDB:<p><b><RatingStar rating={punteggioTMDB} /></b></p></p></div> }
        { utilizzaInfoAttore && <div>Attore: <p><img src={`https://image.tmdb.org/t/p/w185${imgAttore}`} alt="Actor" /> </p>
        <p className="card-text">Nome: {attore}</p> <p>Personaggio: {personaggio} </p></div>}
      </div>
    </div>
  </div>
)}

      </div>
    </div>
  );
};

export default PaginaRisposte;
