import React from "react";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container-fluid">
        <div className="row">
          <div className="col text-center">
            <p>Copyright © {new Date().getFullYear()}</p>
            <p>
              ❤️ by <a href="/">cjgames.it</a> & 🐵 Dev Team{" "}
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
