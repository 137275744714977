//rafce
import React from "react";
import AdminMenu from "../../components/admin/AdminMenu";
import WithAuth from "../../components/WithAuth";

const AdminMenuP = () => {
  return (
    <div className="container">
      
      <WithAuth>
        {({ authenticated }) => (authenticated ? <AdminMenu /> : null)}
      </WithAuth>
    </div>
  );
};

export default AdminMenuP;
