import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import AdminApi from "../../apis/AdminApi";
import { Card } from "react-bootstrap";

const RiscattaCarta = ( { updateTappo } ) => {

  const [cardName, setCardName] = useState(null);
  const [cardImg, setCardImg] = useState(null);
  const [cardRarity, setCardRarity] = useState(null);
  const [mostraCarta, setMostraCarta] = useState(false);
  const [canRedeem, setCanRedeem] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [tapCoin, setTapCoin] = useState();

  const userId = Cookies.get("user_id");

  const getRarityClass = (rarita) => {
    switch (rarita) {
      case "Legendary":
        return "legendary";
      case "Epic":
        return "epic";
      case "Rare":
        return "rare";
      case "Common":
        return "common";
      case "Uncommon":
          return "uncommon";
      // Aggiungi altri casi a seconda delle rarità necessarie
      default:
        return "";
    }
  };
  
  const getRarityCss = (rarita) => {
    switch (rarita) {
      case "Legendary":
        return "pika";
      case "Epic":
        return "mewtwo";
      case "Rare":
        return "eevee";
      case "Common":
        return "charizard";
        case "Uncommon":
          return "charizard";
      // Aggiungi altri casi a seconda delle rarità necessarie
      default:
        return "";
    }
  };

  const riscattaCarta = async (e) => {
    const card = await AdminApi.post(`/u/${userId}/riscattacarta`);

    if(card.data === -1) {setCanRedeem(false)}
    else {
      setCanRedeem(true);
      setCardName(card.data.o_nome_attore);
      setCardImg(card.data.o_immagine);
      setCardRarity(card.data.o_rarita);
      setMostraCarta(true);

      setTapCoin(tapCoin - 100);
      updateTappo(tapCoin - 100);

      await AdminApi.post(`/u/creatransazione/${userId}/TAPCOIN/-100`);
    }
  };

  useEffect(() => {
    const fetchTapCoin = async () => {
      try {
        const tapCoinRes = await AdminApi.get(`/u/${userId}/getriepilogocassa`);

        setTapCoin(tapCoinRes.data.valore)
        
      } catch (error) {
        console.error('Errore durante il recupero della lista film:', error);
      }
    };

    fetchTapCoin();
  }, [userId, tapCoin]);

  return (
    <div className="container">
      <div className="container mt-5">
        <div className="row">
          <div className="col-md-6 offset-md-3">
            <div className="card">
              <div className="card-header bg-primary text-white">
                <h2 className="text-center">Riscatta la tua carta!</h2>
              </div>
              <div className="card-body text-center">
                <h4>Hai a disposizione {tapCoin} TapCoin</h4>
                <div className="text-center">
                    <Card.Img 
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                    onClick={(e) => {
                      e.preventDefault();
                      riscattaCarta();
                    }} src="https://download.cjgames.it/repository/cinezor/img/cards/chest.png"
                    style={{ cursor: isHovered ? 'pointer' : 'default', width: '130px', height: '130px', margin: 'auto' }} ></Card.Img>
                  <button
                    className="btn btn-success btn-lg mb-2 btn-block"
                    onClick={(e) => {
                      e.preventDefault();
                      riscattaCarta();
                    }}
                    data-toggle="modal"
                    data-target="#modal1"
                  >
                    Riscatta Carta <br/>(100 TapCoin)<br/>
                  </button>
                </div>
              </div>
            </div>
            
            {mostraCarta && canRedeem && (<div className="col-md-4 mb-4">
            <Card className={`special pika ${getRarityCss(cardRarity)} ${getRarityClass(cardRarity)}`} style={{ paddingTop: '10px' }}>
            <div className="d-flex justify-content-center align-items-center">
                <Card.Title style={{ textAlign: 'center' }} className="">{cardName} ({cardRarity})</Card.Title></div>
            
              <div style={{ }} className="d-flex justify-content-center align-items-center">
              <Card.Img 
              src={cardImg}
              style={{ width: '95%',cursor: isHovered ? 'pointer' : 'default', margin: 'auto' }} /><br/>
              </div>
              
            </Card>
          </div>
          )
          }
          </div>
        </div>
      </div>
    </div>
  );
};

export default RiscattaCarta;
