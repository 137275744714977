import React, { useState } from 'react';

const CercaCast = () => {
  const [movieId, setMovieId] = useState('');
  const [randomActor, setRandomActor] = useState(null);
  const [actors, setActors] = useState([]);

  const handleChange = (event) => {
    setMovieId(event.target.value);
  };

  const handleMovieIdSubmit = async (event) => {
    event.preventDefault();
    
    try {
      const response = await fetch(`https://api.themoviedb.org/3/movie/${movieId}/credits?api_key=${process.env.REACT_APP_TMDB_API_KEY}&language=en-US`);
      const data = await response.json();
      const filteredActors = data.cast.filter(actor => actor.known_for_department === 'Acting' && actor.order !== undefined && actor.profile_path !== null);
      setActors(filteredActors);
    } catch (error) {
      console.error('Error fetching actors:', error);
    }
  };

  const chooseRandomActor = () => {
    const filteredActors = actors.filter(actor => actor.order !== undefined);
    const randomIndex = Math.floor(Math.random() * filteredActors.length);
    const randomActor = filteredActors[randomIndex];
    setRandomActor(randomActor);
  };

  return (
    <div className="container text-center">
      <h1>Cerca Cast</h1>
      <form onSubmit={handleMovieIdSubmit}>
        <label>
          Movie ID:
          <input type="text" value={movieId} onChange={handleChange} />
        </label>
        <button type="submit">Submit</button>
      </form>
      <h2>Random Actor</h2>
      <button onClick={chooseRandomActor} disabled={!actors.length}>
        Choose Random Actor
      </button>
      {randomActor && (
        <div>
          <img src={`https://image.tmdb.org/t/p/w185${randomActor.profile_path}`} alt="Actor" />
          <h3>{randomActor.name}</h3>
          <p>Character: {randomActor.character}</p>
        </div>
      )}
    </div>
  );
};

export default CercaCast;
