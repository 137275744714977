import React, { useState } from "react";
import { useNavigate } from "react-router-dom"; // Importa useHistory per gestire la navigazione
import Cookies from "js-cookie";
import LoginAuth from "../apis/LoginAuth";
import { useGoogleOneTapLogin } from "@react-oauth/google";

const LoginForm = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  //const [userId, setUserId] = useState("");
  const navigate = useNavigate(); // Ottieni l'istanza di useHistory
  const [mostraErrore, setMostraErrore] = useState(false);
  const [messaggioErrore, setMessaggioErrore] = useState("");

  const handleLogin = async () => {
    try {
      const response = await LoginAuth.post(`/api/login`, {
        email,
        password,
      });

      if (response.status === 200) {
        const accessToken = response.data.accessToken;
        //console.log(response.data.accessToken);
        localStorage.setItem('token', accessToken);
        //const token = localStorage.getItem('token');
        //console.log(token);
        
        Cookies.set("user_id", response.data.id);
        Cookies.set("first_name", response.data.firstName);
        Cookies.set("nickname", response.data.nickname);

        //non usare navigate e non si aggiorna un tubo. serve un fresh start
        window.location.href = '/';

      } else {
        console.error("Failed to login:", response.statusText);
        alert("Failed to login");
      }
    } catch (error) {
      console.error("Error during login:", error);
      
      switch (error.response.status) {
        case 401:
          setMostraErrore(true);
          setMessaggioErrore("Errore 401: Email non trovata");
          break;
        case 403:
          setMostraErrore(true);
          setMessaggioErrore("Errore 403: Email/Password errata");
          break;
        case 405:
            setMostraErrore(true);
            setMessaggioErrore("Errore 405: Utente non verificato. Controlla la mail");
            break;
        case 500:
          setMostraErrore(true);
          setMessaggioErrore("Errore 500: Errore interno al server");
          break;
        default:
          setMessaggioErrore("Errore generico server");
          setMostraErrore(true);
      }

    }
  };

  const forgotPassword = async () => {
    navigate('/forgot-password')
  }

  const handleRegistrationClick = () => {
    navigate("/register"); // Reindirizza l'utente alla pagina di registrazione
  };

  /*
  const handleGuestClick = async () => {
    try {
      const response = await LoginAuth.post(`/api/login`, {
        email: "demo@demo.it",
        password: "demo",
      });

      if (response.status === 200) {

        Cookies.set("first_name", response.data.firstName);
        Cookies.set("user_id", response.data.id);
        Cookies.set("nickname", response.data.nickname);

        // Reindirizza l'utente alla pagina protetta /protected
        window.location.href = '/';

      } else {
        console.error("Failed to login:", response.statusText);
        alert("Failed to login");
      }
    } catch (error) {
      console.error("Error during login:", error);
      
      switch (error.response.status) {
        case 401:
          setMostraErrore(true);
          break;
        case 403:
          setMostraErrore(true);
          break;
        case 500:
          setMostraErrore(true);
          break;
        default:
          setMostraErrore(true);
      }

    }
  };

  */

  //const [scrolling, setScrolling] = useState(false);

  // Funzione per gestire lo scroll della pagina
  /*
  const handleScroll = () => {
    if (window.scrollY > 50) {
      setScrolling(true);
    } else {
      setScrolling(false);
    }
  };
  

  // Aggiungi un ascoltatore per lo scroll quando il componente viene montato
  useEffect(() => {

    window.addEventListener("scroll", handleScroll);
    // Rimuovi l'ascoltatore per lo scroll quando il componente viene smontato
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  */

  useGoogleOneTapLogin({
    onSuccess: (credentialResponse) => {
      //console.log(credentialResponse);
    },
    onError: () => {
      //console.log("Login Failed");
    },
  });

  const handlePasswordKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleLogin(); // Chiama la funzione handleSubmit quando viene premuto il tasto "Invio" nella casella di password
    }
  };

  return (
    <div>   
      <main>
      {mostraErrore && <div className="container mt-5 d-flex justify-content-center align-items-center bg-warning"><br/>{messaggioErrore}</div>}
        <div className="container mt-5 d-flex justify-content-center align-items-center">
          {/* Aggiungi le classi d-flex, justify-content-center, align-items-center e la classe col per definire la larghezza del contenitore */}
          <div className="">
            <div>
              {/* Imposta la larghezza del contenitore a 1/3 della pagina su schermi medi e grandi */}
              <div className="card">
                <div className="card-header">Login</div>
                <div className="card-body">
                  <form>
                    <div className="mb-3">
                      <label htmlFor="email" className="form-label">
                        Email
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        id="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="password" className="form-label">
                        Password
                      </label>
                      <input
                        type="password"
                        className="form-control"
                        id="password"
                        value={password}
                        onKeyDown={handlePasswordKeyDown}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                      />
                    </div>
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={handleLogin}
                    >
                      Login
                    </button>
                  </form>
                  <div className="mt-3">
                    {" "}
                    Non hai un account?{" "}
                    <button
                      type="button"
                      className="btn btn-info"
                      onClick={handleRegistrationClick}
                    >
                      Registrati
                    </button>
                  </div>
                  {/*<!-- Commento per ora !--> 
                  <GoogleLogin
                    onSuccess={(credentialResponse) => {
                      //console.log(credentialResponse);
                    }}
                    onError={() => {
                      //console.log("Login Failed");
                    }}
                  />
                  
                  <div className="mt-3">
                    Oppure...{" "}
                    <button
                      type="button"
                      className="btn btn-success"
                      onClick={handleGuestClick}
                    >
                      Entra come guest
                    </button>
                  </div>
                    */}
                  <div className="mt-3">
                    <button
                      type="button"
                      className="btn btn-danger"
                      onClick={forgotPassword}
                    >Password dimenticata?
                    </button>  
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default LoginForm;
