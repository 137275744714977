import React, { useEffect, useState } from 'react';
import TmbdApi from '../../apis/TmbdApi';
import { useNavigate } from 'react-router-dom';
import RatingStar from '../misc/RatingStar';


const CercaFilm = () => {
    const [query, setQuery] = useState('');
    const [movies, setMovies] = useState([]);

    const [selectedMovie, setSelectedMovie] = useState(null);
    const [selectedMovieId, setSelectedMovieId] = useState();
    const [selectedMovieTitle, setSelectedMovieTitle] = useState();

    const [releaseDate, setReleaseDate] = useState("");
    const [rating, setRating] = useState("");
 
    const [error, setError] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [showConfirm, setShowConfirm] = useState(false); // Stato per gestire la visualizzazione del componente di conferma

    const [isHovered, setIsHovered] = useState(false);

    const navigate = useNavigate();

      // Componente di conferma della selezione del film
    const ConfermaSelezione = ({ onConfirm }) => {
      return (
        <div>
          <button className = "btn-danger" onClick={onConfirm}>Conferma</button>
        </div>
      );
    };

    const MostraDettagliFilm = ({ movie }) => {
      return (
        <div>
          <h2><a href={`https://www.themoviedb.org/movie/${movie.id}`} target="_blank" rel="noopener noreferrer">
                        {movie.title}</a></h2>
          <p><RatingStar rating={movie.vote_average} /></p>
          <p>{movie.overview}</p>
          <p></p>
        </div>
      );
    };
  
    useEffect(() => {
      const searchMovies = async () => {
          try {
            const response = await TmbdApi.get(
              `/search/movie?include_adult=false&language=it-IT&api_key=${process.env.REACT_APP_TMDB_API_KEY}&query=${query}&page=${currentPage}`
            );
        
            const startIndex = (currentPage - 1) * 5;
            const endIndex = startIndex + 5;
            const totalResults = response.data.total_results;
        
            // Calcola il numero totale di pagine necessarie
            const totalPages = Math.ceil(totalResults / 5);
        
            // Imposta i risultati solo se ci sono risultati e la pagina è valida
            if (totalResults > 0 && currentPage <= totalPages) {
              const slicedResults = response.data.results.slice(startIndex, endIndex);
              setMovies(slicedResults);
              setTotalPages(totalPages);
            } else {
              setMovies([]);
              setTotalPages(0);
            }
          } catch (error) {
            setError(error);
          }
      };
  
      if (query !== '') { 
          searchMovies();
      }
  }, [currentPage, query]);
      
    const handleInputChange = (event) => {
      setQuery(event.target.value);
    };
  
    const handleSubmit = (event) => {
      event.preventDefault();
      setCurrentPage(1);
    };
  
    const handleSelectMovie = (movie) => {
      setSelectedMovie(movie);
      setSelectedMovieId(movie.id);
      setSelectedMovieTitle(movie.title);
      setReleaseDate(movie.release_date);
      setRating(movie.vote_average);

      //navigate('/admin/import-film', { state: { filmId: movie.id, filmTitle: movie.title } }); //oppure passare tramite URL l'id

      setShowConfirm(true);
    };
  
    const handlePageChange = (pageNumber) => {
      setCurrentPage(pageNumber);
    };

    const handleConfirm = (movie) => {
        setSelectedMovie(movie);

        console.log(selectedMovie);
        navigate('/admin/import-film', { state: { filmId: selectedMovieId, filmTitle: selectedMovieTitle, releaseDate: releaseDate, rating: rating } }); //oppure passare tramite URL l'id
        
      };

      const goHome = () => {
        navigate(`/`)
      }
  
    return (
      <div className="container">
        <form onSubmit={handleSubmit} className="mb-3">
          <input
            type="text"
            className="form-control"
            placeholder="Cerca un film..."
            value={query}
            onChange={handleInputChange}
          />
          <button type="submit" className="btn btn-primary mt-2">Cerca</button>
        </form>
        {error && <div className="alert alert-danger">Si è verificato un errore: {error.message}</div>}
        <div className="row" style={{ cursor: isHovered ? 'pointer' : 'default'}}>
          <div className="col-md-8">
            <table className="table">
              <thead>
                <tr>
                  <th>Poster</th>
                  <th className="text-center">Titolo</th>
                  <th className="text-center">Voto</th>
                  <th className="text-center">Anno di uscita</th>
                </tr>
              </thead>
              <tbody>
                {movies.map((movie) => (
                  <tr key={movie.id} onClick={() => handleSelectMovie(movie)}>
                    <td>
                      {movie.poster_path && (
                        <img onMouseEnter={() => setIsHovered(true)}
                          onMouseLeave={() => setIsHovered(false)}
                          src={`https://image.tmdb.org/t/p/w200${movie.poster_path}`}
                          alt={movie.title}
                          style={{ maxWidth: '100px' }}
                        />
                      )}
                    </td>
                    <td className="align-middle">
                     <button className="btn-info">{movie.title}</button>
                    </td>
                    <td className="align-middle text-center">{movie.vote_average}</td>
                    <td className="align-middle text-center">{movie.release_date}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <nav aria-label="Paginazione">
                <ul className="pagination justify-content-center">
                    <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                    <button className="page-link" onClick={() => handlePageChange(currentPage - 1)}>Precedente</button>
                    </li>
                    <li className={`page-item ${currentPage === totalPages || totalPages === 0 ? 'disabled' : ''}`}>
                    <button className="page-link" onClick={() => handlePageChange(currentPage + 1)}>Successivo</button>
                    </li>
                </ul>
            </nav>
          </div>
          <div className="col-md-4">
          {/* Visualizza DettagliFilm solo se è stato selezionato un film e non è visualizzato il componente di conferma */}
          {showConfirm && <MostraDettagliFilm movie={selectedMovie} onConfirm={handleConfirm} />}
          
          {/* Visualizza il componente di conferma solo se è stato selezionato un film e non è undefined */}
          {showConfirm && selectedMovie && <ConfermaSelezione movie={selectedMovie} onConfirm={handleConfirm} />}
        </div>
        </div>

        <div>
        <button className="btn-secondary" onClick={goHome}>Vai alla Home</button>
      </div>
      </div>
    );
  };  

export default CercaFilm;
