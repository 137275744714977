import React, { useEffect, useState } from "react";
import LoginAuth from "../apis/LoginAuth";
import { useNavigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";

function RegistrationForm() {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    nickname: "",
    email: "",
    password: "",
    confirmPassword: "",
  });
  const [passwordError, setPasswordError] = useState("");
  const [registrationError, setRegistrationError] = useState("");
  const [registrationSuccess, setRegistrationSuccess] = useState(false);

  const [captchaToken, setCaptchaToken] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [keyReCapatcha, setKeyRecapatcha] = useState('');

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    if (name === "confirmPassword") {
      setPasswordError(
        value !== formData.password ? "Le password non corrispondono" : ""
      );
    }
  };

  useEffect(() => {
    const sendConfirmationEmail = async () => {

      calcolaKeyRecaptcha(process.env.REACT_APP_ENVIRONMENT);

      if (registrationSuccess) {
        try {
          await LoginAuth.post("/send-confirmation-email", {
            email: formData.email,
            firstName: formData.firstName,
          });

          setTimeout(() => {
            navigate("/"); // Reindirizza l'utente alla pagina di successo dopo un secondo
          }, 1000);
        } catch (error) {
          console.error("Errore durante l'invio dell'email di conferma:", error);
          setRegistrationError("Si è verificato un errore durante l'invio dell'email di conferma.");
        }
      }
    };

    sendConfirmationEmail();
  }, [registrationSuccess, formData, navigate]);

  const calcolaKeyRecaptcha = (envinroment) => {
    
    switch (envinroment) {
      case 'test':
        setKeyRecapatcha(process.env.REACT_APP_RECAPTCHA_SITE_KEY_TEST);
        break;
      case 'production':
        setKeyRecapatcha(process.env.REACT_APP_RECAPTCHA_SITE_KEY_PRODUCTION);
        break;
      default:
        return null;

    }
  }

  const handleCaptchaChange = (token) => {
    setCaptchaToken(token);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setRegistrationError("");
    setRegistrationSuccess(false);

    setSubmitting(true); // Imposta lo stato del submit su true per evitare ulteriori invii

    try {
      await LoginAuth.post("/api/signup", formData);
      //console.log(response.data); // Gestisci la risposta come desideri
      setRegistrationSuccess(true);

      //console.log("Registrazione avvenuta con successo!");

      setTimeout(() => {
        navigate("/"); // Reindirizza l'utente alla pagina di successo dopo un secondo
      }, 1000);
    } catch (error) {
      console.error("Errore durante la registrazione: Utente già presente", error);
      setRegistrationError(
        "Errore durante la registrazione: Utente già presente"
      );
    }
  };

  return (
    <div className="container">
      <h2>Registrazione</h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <label htmlFor="firstName" className="form-label">
            Firstname:
          </label>
          <input
            type="text"
            className="form-control"
            id="firstName"
            name="firstName"
            value={formData.firstname}
            onChange={handleChange}
            placeholder="Inserisci il tuo nome"
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="lastName" className="form-label">
            Lastname:
          </label>
          <input
            type="text"
            className="form-control"
            id="lastName"
            name="lastName"
            value={formData.lastname}
            onChange={handleChange}
            placeholder="Inserisci il tuo cognome"
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="nickname" className="form-label">
            Nickname:
          </label>
          <input
            type="text"
            className="form-control"
            id="nickname"
            name="nickname"
            value={formData.nickname}
            onChange={handleChange}
            placeholder="Scegli un nickname"
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="email" className="form-label">
            Email:
          </label>
          <input
            type="email"
            className="form-control"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            placeholder="Inserisci la tua email"
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="password" className="form-label">
            Password:
          </label>
          <input
            type="password"
            className="form-control"
            id="password"
            name="password"
            value={formData.password}
            onChange={handleChange}
            placeholder="Inserisci la tua password"
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="confirmPassword" className="form-label">
            Conferma Password:
          </label>
          <input
            type="password"
            className="form-control"
            id="confirmPassword"
            name="confirmPassword"
            value={formData.confirmPassword}
            onChange={handleChange}
            placeholder="Conferma la tua password"
            required
          />
          {passwordError && (
            <span style={{ color: "red" }}>{passwordError}</span>
          )}
        </div>
        <button type="submit" className="btn btn-primary"  disabled={!captchaToken || submitting}>
          Registrati
        </button>
        <p></p>
        <ReCAPTCHA
              sitekey={keyReCapatcha}
              onChange={handleCaptchaChange}
            />
      </form>
      {registrationError && (
        <div className="alert alert-danger mt-3">{registrationError}</div>
      )}
      {registrationSuccess && (
        <div className="alert alert-success mt-3">
          Registrazione avvenuta con successo!
        </div>
      )}
    </div>
  );
}

export default RegistrationForm;
