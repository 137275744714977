//rafce
import React from "react";
import WithAuth from "../../../components/WithAuth";
import PaginaRisposte from "../../../components/FilmPaginaRisposte";

const QuizRispostePage = () => {
  return (
    <div className="container">
      <WithAuth>
        {({ authenticated }) => (authenticated ? <PaginaRisposte /> : null)}
      </WithAuth>
    </div>
  );
};

export default QuizRispostePage;
