import React from 'react'

const LoadingTimer = () => {
  return (
    <div className="box">
              <div className="circle circle1"></div>
              <div className="circle circle2"></div>
              <div className="niddle"></div>
              <div className="number">
                  <div>5</div>
                  <div>4</div>
                  <div>3</div>
                  <div>2</div>
                  <div>1</div>
              </div>
          </div>
  )
}

export default LoadingTimer