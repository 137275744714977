import React from "react";

const Err403 = () => {
  return (
    <div>
      <h3 className="font-weight-light text-center">
      403 Forbidden
      </h3>
    </div>
  );
};

export default Err403;
