//rafce
import React from "react";
import Register from "../../components/RegisterForm";

const RegisterP = () => {
  return (
    <div className="container">
      <Register />
    </div>
  );
};

export default RegisterP;
