// CountdownContext.js
import React, { createContext, useContext, useEffect, useState } from "react";

export const CountdownContext = createContext();

export const useCountdown = () => useContext(CountdownContext);

export const CountdownProvider = ({
  children,
  initialSeconds,
  initialMinutes,
}) => {
  const [minutes, setMinutes] = useState(initialMinutes);
  const [seconds, setSeconds] = useState(initialSeconds);
  const [isTimeOver, setIsTimeOver] = useState(false);

  useEffect(() => {
    if (minutes === 0 && seconds === 0) {
      setIsTimeOver(true);
    }
  }, [minutes, seconds]);

  return (
    <CountdownContext.Provider
      value={{ minutes, seconds, setMinutes, setSeconds, isTimeOver }}
    >
      {children}
    </CountdownContext.Provider>
  );
};
