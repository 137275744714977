// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dark-mode-toggle {
    display: flex;
    justify-content: space-between;
    width: 200px; /* Larghezza del toggle */
    margin: 0 auto; /* Centra il toggle */
  }
  
  .mode-button {
    background-color: #ddd;
    color: #333;
    border: none;
    border-radius: 2px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
  }
  
  .mode-button.active {
    background-color: #343a40; /* Colore di sfondo del bottone attivo */
    color: #fff; /* Colore del testo del bottone attivo */
  }
  
  .mode-button:not(.active):hover {
    background-color: #ccc; /* Cambia il colore di sfondo al passaggio del mouse */
  }`, "",{"version":3,"sources":["webpack://./src/components/css/switchbutton.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;IAC9B,YAAY,EAAE,yBAAyB;IACvC,cAAc,EAAE,qBAAqB;EACvC;;EAEA;IACE,sBAAsB;IACtB,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,kBAAkB;IAClB,eAAe;IACf,eAAe;IACf,6CAA6C;EAC/C;;EAEA;IACE,yBAAyB,EAAE,wCAAwC;IACnE,WAAW,EAAE,wCAAwC;EACvD;;EAEA;IACE,sBAAsB,EAAE,sDAAsD;EAChF","sourcesContent":[".dark-mode-toggle {\n    display: flex;\n    justify-content: space-between;\n    width: 200px; /* Larghezza del toggle */\n    margin: 0 auto; /* Centra il toggle */\n  }\n  \n  .mode-button {\n    background-color: #ddd;\n    color: #333;\n    border: none;\n    border-radius: 2px;\n    padding: 10px 20px;\n    font-size: 16px;\n    cursor: pointer;\n    transition: background-color 0.3s, color 0.3s;\n  }\n  \n  .mode-button.active {\n    background-color: #343a40; /* Colore di sfondo del bottone attivo */\n    color: #fff; /* Colore del testo del bottone attivo */\n  }\n  \n  .mode-button:not(.active):hover {\n    background-color: #ccc; /* Cambia il colore di sfondo al passaggio del mouse */\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
