import React from 'react';
import UserProfile from './ProfileHome';
import { useParams } from 'react-router-dom';

const UserHomePage = () => {
  // Otteniamo l'ID utente dall'URL utilizzando useParams()
  const userId  = useParams();

  return (
    <div className="container">
      <h2>Profilo Utente</h2>
      {/* Passiamo l'ID utente come prop al componente UserProfile */}
      <UserProfile userId={userId.id} />
    </div>
  );
};

export default UserHomePage;
