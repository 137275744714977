import React, { useState } from 'react';
import { DndContext, closestCenter, DragOverlay } from '@dnd-kit/core';
import { SortableContext, arrayMove, rectSortingStrategy } from '@dnd-kit/sortable';
import SortableItem from './SortableItem';
import Droppable from './Droppable';

const Example2 = () => {
  const [items1, setItems1] = useState(['Item 1', 'Item 2', 'Item 3']);
  const [items2, setItems2] = useState(['Item 4', 'Item 5', 'Item 6']);
  const [activeId, setActiveId] = useState(null);

  const handleDragStart = (event) => {
    setActiveId(event.active.id);
  };

  const handleDragEnd = (event) => {
    const { active, over } = event;
    setActiveId(null);

    if (!over) return;

    const activeContainer = active.data.current.sortable.containerId;
    const overContainer = over.data.current?.sortable?.containerId || over.id;

    if (activeContainer !== overContainer) {
      setItems1((items1) => {
        if (activeContainer === 'items1') {
          const activeIndex = items1.indexOf(active.id);
          const newItems1 = [...items1];
          newItems1.splice(activeIndex, 1);
          setItems2((items2) => [...items2, active.id]);
          return newItems1;
        }
        return items1;
      });

      setItems2((items2) => {
        if (activeContainer === 'items2') {
          const activeIndex = items2.indexOf(active.id);
          const newItems2 = [...items2];
          newItems2.splice(activeIndex, 1);
          setItems1((items1) => [...items1, active.id]);
          return newItems2;
        }
        return items2;
      });
    } else {
      if (activeContainer === 'items1') {
        const oldIndex = items1.indexOf(active.id);
        const newIndex = items1.indexOf(over.id);
        setItems1((items) => arrayMove(items, oldIndex, newIndex));
      } else {
        const oldIndex = items2.indexOf(active.id);
        const newIndex = items2.indexOf(over.id);
        setItems2((items) => arrayMove(items, oldIndex, newIndex));
      }
    }
  };

  return (
    <DndContext
      collisionDetection={closestCenter}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
    >
    <div className="container">
      <div style={{ display: 'flex', justifyContent: 'space-around' }}>
        <Droppable id="items1">
          <SortableContext id="items1" items={items1} strategy={rectSortingStrategy}>
            {items1.map((id) => (
              <SortableItem key={id} id={id} />
            ))}
          </SortableContext>
        </Droppable>

        <Droppable id="items2">
          <SortableContext id="items2" items={items2} strategy={rectSortingStrategy}>
            {items2.map((id) => (
              <SortableItem key={id} id={id} />
            ))}
          </SortableContext>
        </Droppable>
      </div>
      <DragOverlay>
        {activeId ? <SortableItem id={activeId} /> : null}
      </DragOverlay>
      </div>
    </DndContext>
  );
};

export default Example2;
