import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";
import { FilmsContextProvider } from "./context/FilmsContext";


import HomeIndex from "./routes/quiz/film/HomeQuizIndex";
import FineQuiz from "./components/FineQuiz";
import Login from "./components/Login";
import RegisterP from "./routes/user/RegisterPage";
import './css/app.css';
import Navigation from "./components/Navigation";
import Footer from "./components/Footer";
import FilmDelGiornoP from "./routes/misc/FilmDelGiorno";
import ImportaRisposteP from "./routes/admin/ImportRisposte";
import AdminMenuP from "./routes/admin/AdminMenu";
import { GoogleOAuthProvider } from "@react-oauth/google";
import CheckChiusura from "./components/quiz/CheckChiusura";
import UserProfileP from "./routes/user/ProfileHome";
import QuizRispostePage from "./routes/quiz/film/QuizRispostePage";
import CercaFilmP from "./routes/admin/CercaFilmPage";
import NavigationP from "./routes/NavigationPage";
import HeaderSwitch from "./components/WithAuthHeaderSwitch";
import NavigationGuest from "./components/NavigationGuest";
import FilmList from "./components/user/FilmList";
import AchievementScreen from "./components/Achievements";
import ClassificaGlobale from "./components/misc/ClassificaGlobale";
import LastSessions from "./components/user/LastSessions";
import AchievementNotifier from "./components/AchievementNotifier";
import CollezioneCarte from "./components/misc/CollezioneCarte";
import RiscattaCarta from "./components/misc/RiscattaCarta";
import LastTransactions from "./components/user/LastTransactions";
import StartTimerQuiz from "./routes/quiz/film/StartTimerQuiz";
import Err401 from "./components/errors/401";
import Err403 from "./components/errors/403";
import Err500 from "./components/errors/500";
import FriendRequests from "./components/user/FriendRequests";
import ResetPassword from "./components/user/ResetPassword";
import ForgotPassword from "./components/user/ForgotPassword";
import CercaCast from "./components/admin/CercaCast";
import AdminApi from "./apis/AdminApi";
import Cookies from "js-cookie";
import Example from "./components/drag/Example";
import Example2 from "./components/drag/Example2";
import Example3 from "./components/drag/Example3";

const App = () => {
  const ScrollToTop = () => {
    const { pathname } = useLocation();
  
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);
  
    return null;
  };

  const [tappo, setTappo] = useState();
  const [versione, setVersione] = useState("");

  // Valore iniziale
  useEffect(() => {
    const fetchTapCoin = async () => {
      try {
        const tapCoinRes = await AdminApi.get(`/u/${Cookies.get("user_id")}/getriepilogocassa`);
        setTappo(tapCoinRes.data.valore)

        const getVersion = await AdminApi.get(`/dev-version`);
        setVersione(getVersion.data.versione);
        //console.log(getVersion.data.versione);
        
      } catch (error) {
        console.error('Errore durante il recupero dei tapCoin:', error);
      }
    };

    fetchTapCoin();
  }, []);

  // Funzione di aggiornamento per tappo
  const updateTappo = (newTappo) => {
    setTappo(newTappo);
  };


  return (
    <FilmsContextProvider>
        <div className="header">
        <HeaderSwitch>
        {({ authenticated }) => (
          authenticated ? <Navigation tappo={tappo} appVersion = {versione}/> : <NavigationGuest />
        )}
      </HeaderSwitch>
        </div>
        <div style={{ minHeight: '100vh', marginTop: '10vh' }}>
          <Router>
          <ScrollToTop />
            <Routes>
              <Route path="/" element={<HomeIndex />} />
              <Route path="/quiz/:userId/:id_sessione/:id_categoria/:id_pagina" element={<QuizRispostePage />} />
              <Route path="/quiz/:userId/:id_sessione/:id_categoria/:id_pagina/:closed" element={<QuizRispostePage />} />
              <Route path="/quiz/:userId/:id_sessione/checkchiusura/" element={<CheckChiusura />} />
              <Route path="/quiz/:userId/:id_sessione/fine" element={<FineQuiz />} />
              <Route path="/register" element={<RegisterP />} />
              <Route path="/login" element={<GoogleOAuthProvider clientId="655423729532-37nncnsb93aksn1sk3lfukb24l2mqi3a.apps.googleusercontent.com"><Login /></GoogleOAuthProvider>} />
              <Route path="/motd" element={<FilmDelGiornoP />} />
              <Route path="/admin" element={<AdminMenuP/>} />
              <Route path="/admin/import-film" element={<ImportaRisposteP />} />
              <Route path="/admin/cerca-film" element={<CercaFilmP />} />
              <Route path="/u/:userId/achievements" element={<AchievementScreen />} />
              <Route path="/u/:id" element={<UserProfileP />} />
              <Route path="/u/:userId/movielist" element={<FilmList />} />
              <Route path="/u/:userId/sessions" element={<LastSessions />} />
              <Route path="/auth" element={<NavigationP/>}/>
              <Route path="/g/leaderboard" element={<ClassificaGlobale />} />
              <Route path="/quiz/:userId/:id_sessione/listacategorie" element={<StartTimerQuiz />} />
              <Route path="/u/:userId/collections" element={<CollezioneCarte />} />
              <Route path="/u/:userId/getnewcard" element={<RiscattaCarta updateTappo={updateTappo}/>} />
              <Route path="/u/:userId/transactions" element={<LastTransactions />} />
              <Route path="/g/:userId/achievementnotifier" element={<AchievementNotifier />}/>
              <Route path="/e/401" element={<Err401 />}/>
              <Route path="/e/403" element={<Err403 />}/>
              <Route path="/e/500" element={<Err500 />}/>
              <Route path="/u/addfriend/:userId" element={<FriendRequests />}/>
              <Route path="/forgot-password" element={<ForgotPassword />}/>
              <Route path="/reset-password/:token" element={<ResetPassword />}/>
              <Route path="/admin/cerca-cast" element={<CercaCast />}/>
              <Route path="/test/1/drag" element={<Example />}/>
              <Route path="/test/2/drag" element={<Example2 />}/>
              <Route path="/test/3/drag" element={<Example3 />}/>
            </Routes>
          </Router>
        </div>
        <div className="footer">
          <Footer />
        </div>
    </FilmsContextProvider>
  )
}

export default App;
