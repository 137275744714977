import React from 'react';
import { Link } from 'react-router-dom'; // Se stai utilizzando React Router per la navigazione
import '../../components/css/adminmenu.css'

function AdminMenu() {
    return (
        <div className="admin-menu">
        <h2 className="text-center mb-4">Menu Admin</h2>
        <div className="row justify-content-center">
          <div className="col-3">
            <Link to="/admin/dashboard" className="btn btn-warning btn-lg btn-block mb-3 font-weight-bold disabled">Dashboard</Link>
            <Link to="/admin/cerca-film" className="btn btn-success btn-lg btn-block mb-3 font-weight-bold">Gestione Film</Link>
            <Link to="/admin/categories" className="btn btn-warning btn-lg btn-block mb-3 font-weight-bold disabled">Gestione Categorie</Link>
            <Link to="/admin/users" className="btn btn-warning btn-lg btn-block mb-3 font-weight-bold disabled">Gestione Utenti</Link>
            {/* Altri link per le varie funzionalità dell'interfaccia amministratore */}
          </div>
        </div>
      </div>
    );
  }
  
export default AdminMenu;
