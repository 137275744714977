import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import '../components/css/globals.css';
import AdminApi from "../apis/AdminApi";

function Navigation( {tappo, appVersion}) {

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [score, setScore] = useState(0);
  const [award, setAward] = useState();
  const [requests, setRequests] = useState(0);

  const userId = Cookies.get("user_id");

  const handleLogout = () => {
    localStorage.removeItem('token');
    Cookies.remove("token");
    Cookies.remove("user_id");
    
    //setAuthenticated(false);
  };

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if(userId !== undefined) {
        const getScore = await AdminApi.get(`/u/${userId}/getriepilogocassa`);
        setScore(getScore.data.valore);
        }

        const getAward = await AdminApi.get(`/u/${userId}/achihunter`);
        setAward(getAward.data.valore);

        const newRequests = await AdminApi.get(`/g/contapending/${userId}`);
        setRequests(newRequests.data.numero_pending);

        //console.log(getAward.data.valore)

      } catch (error) {
        // Gestione degli errori qui
        console.error('Errore durante il recupero dei dati:', error);
      }
    };

    fetchData(); // Chiamata alla funzione fetchData() al montaggio del componente

  }, [userId, score, award]); 

  return (
    <nav className={`navbar navbar-expand-lg navbar-dark bg-dark ${isMenuOpen ? 'open-menu' : ''}`}>
  <div className="container-fluid">
    <a className="navbar-brand" href="/">
      cjgames.it
    </a>
    <button
      className="navbar-toggler"
      type="button"
      onClick={handleMenuToggle}
    >
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className={`collapse navbar-collapse ${isMenuOpen ? 'show' : ''}`}>
      <ul className="navbar-nav">
      <li className="nav-item">
        <a className="nav-link" href="/">
          (Versione {appVersion})
          </a>
        </li>
        <li className="nav-item">
          <a className="nav-link" href="/">
          🏠 Home
          </a>
        </li>
        <li className="nav-item separator">
          <hr className="separator-line" />
        </li>
      </ul>
      <ul className="navbar-nav ml-auto">
      <li className="nav-item">
          <a className="nav-link" href={`/u/${userId}/transactions`}>
           🪙 <b>{tappo}</b> TapCoin 
          </a>
        </li>
        <li className="nav-item">
          <a className="nav-link" href={`/u/${Cookies.get("user_id")}`}>
            👤 {award === "0" && <img src="https://download.cjgames.it/repository/cinezor/img/achievements/completionist.svg" width="25px" height="25px" alt="achihunter"></img> } 
            {Cookies.get("nickname")} ({requests})
          </a>
        </li>
        <li className="nav-item">
            <a href="/" className="btn btn-danger" onClick={handleLogout} style={{marginLeft: "10px"}}>
              Logout
            </a>
        </li>
      </ul>
    </div>
  </div>
</nav>

  );
}

export default Navigation;
