import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Table } from 'react-bootstrap';
import AdminApi from '../../apis/AdminApi';

function FriendPendingRequests({ idUtente, setRefreshList }) {
  //const { userId } = useParams();
  const [listaUtenti, setListaUtenti] = useState([]);
  const [mostraRequest, setMostraRequest] = useState(false);

  const navigate = useNavigate();

  const token = localStorage.getItem('token');
  //console.log(token);

  const decodeRequest = (tipo_azione) => {
    switch (tipo_azione) {
      case 1:
        return 'accept'
      case 2:
        return  'decline'
      case 3:
        return 'ignore'
      default:
        return null;

    }
  }

  const confirmFriendRequest = async (friendId, tipoAzione) => {

    try {
       //console.log(token);
       //console.log(idUtente);
       //console.log(friendId);

        await AdminApi.post(`/api/friendrequests/${decodeRequest(tipoAzione)}`, 
        { fromUserId: friendId,
            toUserId: idUtente
         }, {
            headers: {
              Authorization: `Bearer ${token}`
            }});

            //console.log(decodeRequest(tipoAzione));
            

            // Aggiorna la lista degli utenti dopo aver accettato la richiesta di amicizia
            const updatedUsers = listaUtenti.filter(user => user.id !== friendId);
            setListaUtenti(updatedUsers);

            setRefreshList(true);
            
    }
    catch(error) {
        switch (error.response.status) {
            case 401:
              navigate('/e/401');
              break;
            case 403:
              navigate('/e/403');
              break;
            case 500:
              navigate('/e/500');
              break;
            default:
              console.error('Errore generico:', error);
          }
    }
  }

  useEffect(() => {
    
    const fetchUtenti = async () => {
      try {
        //console.log(token);
        const token = localStorage.getItem('token');

        // Effettua la richiesta per ottenere le informazioni del giocatore
        //console.log(idUtente);

        const listaUtenti = await AdminApi.post(`/g/listapending`, { 
          user_id: idUtente
        }, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });

        //console.log(idUtente);
        //console.log(listaUtenti);

        setListaUtenti(listaUtenti.data);

        setMostraRequest(true);
        
      } catch (error) {
        console.error('Errore durante il recupero delle transazioni:', error);

        switch (error.response.status) {
          case 401:
            setMostraRequest(false);
            break;
          case 403:
            setMostraRequest(false);
            break;
          case 500:
            setMostraRequest(false);
            break;
          default:
            console.error('Errore durante il recupero delle transazioni:', error);
        }
      
      }
    };

    fetchUtenti();
  }, [idUtente, navigate, token]);

  

  return (
    <div className="container">
      {mostraRequest && <div><h2>Richieste di amicizia in sospeso</h2>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th style={{ width: '5%' }}>UserId</th>
            <th style={{ width: '25%' }}>Username</th>
            <th style={{ width: '20%' }}>Azione</th>
          </tr>
        </thead>
        <tbody>
        {listaUtenti.map((utente, index) => (
          <tr key={index}>
            <td>{utente.id}</td>
            <td><a href={`/u/${utente.id}`}>{utente.from_nickname}</a></td>
            <td>
              <div>
                <button className="btn-success" onClick={() => confirmFriendRequest(utente.id, 1)}>Accetta</button>
                <button className="btn-danger" onClick={() => confirmFriendRequest(utente.id, 2)}>Rifiuta</button>
                <button className="btn-warning" onClick={() => confirmFriendRequest(utente.id, 3)}>Ignora</button>
              </div>
            </td>
          </tr>
        ))}
      </tbody>

      </Table> 
      </div>}
    </div>
  );
}

export default FriendPendingRequests;
