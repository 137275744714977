//rafce
import React from "react";
import ListaCategorie from "../../../components/misc/ListaCategorie";
import OldTimer from "../../../components/OldTimer";

const StartTimerQuiz = () => {

  //accrocchio utilizzato ispirato al WithAuth, si può sicuramente fare meglio

  return (
    <div className="container">
      <OldTimer>
        {({ authenticated }) => (authenticated ? <ListaCategorie /> : null)}
      </OldTimer>
    </div>
  );
};

export default StartTimerQuiz;
