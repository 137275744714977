import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Table } from 'react-bootstrap';
import AdminApi from '../../apis/AdminApi';

function FriendRequests() {
  const { userId } = useParams();
  const [listaUtenti, setListaUtenti] = useState([]);
  const location = useLocation();
  const idUtenteAdd = location.state && location.state.idUtenteAdd;
  console.log(idUtenteAdd);

  const navigate = useNavigate();
  const sendFriendRequest = async (friendId) => {

    try {
        //console.log(friendId);
        const token = localStorage.getItem('token');
        //console.log(token);

        await AdminApi.post(`/api/friendrequests/send`, 
        { fromUserId: userId,
            toUserId: friendId
         }, {
            headers: {
              Authorization: `Bearer ${token}`
            }});

            const updatedUsers = listaUtenti.filter(user => user.id !== friendId);
            setListaUtenti(updatedUsers);
    }
    catch(error) {
        switch (error.response.status) {
            case 401:
              navigate('/e/401');
              break;
            case 403:
              navigate('/e/403');
              break;
            case 500:
              navigate('/e/500');
              break;
            default:
              console.error('Errore durante il recupero delle transazioni:', error);
          }
    }
  }

  useEffect(() => {
    
    const fetchUtenti = async () => {
      try {
        //console.log(token);

        // Effettua la richiesta per ottenere le informazioni del giocatore
        const listaUtenti = await AdminApi.get(`/g/listautenti/${userId}/${idUtenteAdd}`);

        setListaUtenti(listaUtenti.data);
      } catch (error) {
        console.error('Errore durante il recupero delle transazioni:', error);

        switch (error.response.status) {
          case 401:
            navigate('/e/401');
            break;
          case 403:
            navigate('/e/403');
            break;
          case 500:
            navigate('/e/500');
            break;
          default:
            console.error('Errore durante il recupero delle transazioni:', error);
        }
      
      }
    };

    fetchUtenti();
  }, [userId, navigate, idUtenteAdd]);

  

  return (
    <div className="container">
      <h2>Aggiungi i tuoi amici</h2>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th style={{ width: '5%' }}>UserId</th>
            <th style={{ width: '25%' }}>Username</th>
            <th style={{ width: '20%' }}>Link Profilo</th>
            <th style={{ width: '20%' }}>Invia Richiesta</th>
          </tr>
        </thead>
        <tbody>
          {listaUtenti.map((utente, index) => (
            <tr key={index}>
              <td><b>{utente.id}</b></td>
              <td><b>{utente.nickname}</b></td>
              <td><a href={`/u/${utente.id}`}>Link profilo</a></td>
              <td><button className="btn-success" onClick={()=>sendFriendRequest(utente.id)}>Invia Richiesta</button></td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
}

export default FriendRequests;
