import React, { useState, useEffect } from "react";
import AdminApi from "../apis/AdminApi";

const HeaderSwitch = ({ children }) => {
  const [authenticated, setAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkAuthentication = async () => {
      const token = localStorage.getItem('token');
    
      if (token) {
        try {
          const response = await AdminApi.get("/protected", {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
    
          //console.log(response);
    
          if (response.status === 200) {
            setAuthenticated(true);
          } else {
            setAuthenticated(false); // Aggiungi questo per gestire il caso in cui la richiesta non restituisce uno status 200
          }
        } catch (error) {
          console.error(
            "Errore durante la verifica dell'autenticazione:",
            error
          );
          setAuthenticated(false); // Aggiungi questo per gestire il caso in cui la richiesta fallisce
        }
      } else {
        setAuthenticated(false); // Assicurati che authenticated sia impostato su false se non c'è un token
      }
      
    };
    
    setLoading(false); // no timeout

    checkAuthentication();

  }, []);

  if (loading) {
    return (
      <nav className="navbar navbar-expand-lg">
      <div className="container">
        <a className="navbar-brand" href="https://www.cjgames.it">
          cjgames.it
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          
        </div>
      </div>
    </nav>
    );
  }

  return <>{children({ authenticated })}</>;
};

export default HeaderSwitch;
