import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMedal } from "@fortawesome/free-solid-svg-icons";
import "../../src/components/css/globals.css";
import "../../src/components/css/achievements.css";
import AdminApi from "../apis/AdminApi";
import { useParams } from "react-router-dom";

const AchievementScreen = () => {
  const [achievements, setAchievements] = useState([]);
  const [contaAchi, setContaAchi] = useState(0);
  const [totAchi, setTotAchi] = useState(0);
  const [percAchi, setPercAchi] = useState(0);
  const [perfectAchi, setPerfectAchi] = useState(false);
  const [showPerfect, setShowPerfect] = useState();
  const [nickname, setNickname] = useState();
  const { userId } = useParams();

  const getIsAchieved = (achieved) => {
    switch (achieved) {
      case 1:
        return "achieved";
      case 0:
        return;
      default:
        return; // Valore predefinito nel caso in cui la modalità non corrisponda a nessun caso
    }
  };

  useEffect(() => {
    const fetchAchievements = async () => {
      try {
        const contaAchi = await AdminApi.get(
          `/u/${userId}/contaachievementutente`
        );
        setContaAchi(contaAchi.data.conta_achi);
        setTotAchi(contaAchi.data.tot_achi);
        setPercAchi(
          (contaAchi.data.conta_achi / contaAchi.data.tot_achi) * 100
        );

        const nick = await AdminApi.get(`/u/${userId}/getinfo`);
        setNickname(nick.data.nickname);

        if (
          (contaAchi.data.conta_achi / contaAchi.data.tot_achi) * 100 ===
          100
        ) {
          setPerfectAchi(true);
          setShowPerfect(
            "https://download.cjgames.it/repository/cinezor/img/achievements/completionist.svg"
          );
          //console.log((contaAchi.data.conta_achi / contaAchi.data.tot_achi)*100)
          console.log(perfectAchi);
        }

        const response = await AdminApi.get(`/u/${userId}/achievements`);
        setAchievements(response.data);
      } catch (error) {
        console.error("Errore durante il recupero degli achievement:", error);
      }
    };

    fetchAchievements();
  }, [contaAchi, perfectAchi, userId]);

  return (
    <Container fluid>
      <Row className="mt-5 wrap">
        <Col md={{ span: 6, offset: 3 }}>
          <h1 className="text-center mb-4">
            Achievements di {nickname} [<font color="green">{percAchi}%</font>]{" "}
          
            {perfectAchi && <><br/><img src={`${showPerfect}`} alt="Perfezionista!"></img><br/>Perfezionista!</>
            }
            <div className="progress">
              <div
                className="progress-bar"
                role="progressbar"
                style={{ width: `${(percAchi)}%` }}
                aria-valuenow={percAchi}
                aria-valuemin="0"
                aria-valuemax="100"
              >
                {contaAchi}/{totAchi}
              </div>
            </div>
            </h1>
          {achievements.map((achievement, index) => (
            <Card key={index} className={achievement.achieved === 1 ? (getIsAchieved(achievement.achieved) ) : "achi-container not-achieved"}>
            {achievement.achieved === 1 && (
              <div className="ribbon-top-right gold">
                <FontAwesomeIcon icon={faMedal} />
              </div>
            )}
            <Card.Body className="bg-dark text-light">
              <Row>
                {/* Aggiunta di una colonna extra */}
                <Col xs={1} ><br/>
                {achievement.achieved === 1 ? ( // Renderizza l'immagine solo se achieved è uguale a 1
                  <img
                    src="https://download.cjgames.it/repository/cinezor/img/achievements/icons8-clapperboard_closed.png"
                    alt="achieved"
                    width="30px"
                  />
                ) : ( // Altrimenti, renderizza un'icona diversa o nulla
                  <img
                    src="https://download.cjgames.it/repository/cinezor/img/achievements/icons8-clapperboard-open.png"
                    alt="not-achieved"
                    width="30px"
                  />
                )}
                
                </Col>
                {/* Colonne esistenti */}
                <Col xs={2}>
                  <div className="wrap">
                    <div
                      className={`AchievementIconWrapper ${achievement.rarita}Achievement`}
                    >
                      <img
                        src={achievement.url_img}
                        alt="Achievement Icon"
                        className="achievementImage"
                      />
                      <div className={` ${achievement.achieved === 1 ? "AchievementIconGlowContainerRoot" : "not-achieved"}`}>
                        <div className={` ${achievement.achieved === 1 ? "AchievementIconGlowContainer" : ""}`}>
                          <div className={` ${achievement.achieved === 1 ? "AchievementIconGlow" : ""}`}></div>
                        </div>
                      </div>
                      <div className="fakeIcon"></div>
                    </div>
                  </div>
                </Col>
                <Col xs={8}>
                  <h5 className={`card-title ${achievement.achieved === 1 ? "" : "not-achieved"}`}>
                    {achievement.nome_achievement} ({achievement.rarita})
                  </h5>
                  <p className={`card-text ${achievement.achieved === 1 ? "" : "not-achieved"}`}>{achievement.descr_achievement}</p>
                  <div className="progress">
                    <div
                      className="progress-bar"
                      role="progressbar"
                      style={{ width: `${(achievement.progresso_attuale / achievement.progresso_totale) * 100}%`, minWidth: '15%' }}
                      aria-valuenow={achievement.progresso_attuale}
                      aria-valuemin="0"
                      aria-valuemax="100"
                    >
                      {achievement.progresso_attuale}/{achievement.progresso_totale}
                    </div>
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
          ))}
        </Col>
      </Row>
    </Container>
  );
};

export default AchievementScreen;
