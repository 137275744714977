//rafce
import React from "react";
import WithAuth from "../components/WithAuth";
import Navigation from "../components/Navigation";
import NavigationGuest from "../components/NavigationGuest";


const NavigationP = () => {
  const renderNavigation = ({ authenticated }) => {
    // Utilizza il valore di authenticated qui
    //console.log("Authenticated:", authenticated);

    return (
      <div className="container">
        {authenticated ? <Navigation /> : <NavigationGuest />}
      </div>
    );
  };

  return (
    <div className="container">
      <WithAuth>
        {renderNavigation}
      </WithAuth>
    </div>
  );
};

export default NavigationP;
