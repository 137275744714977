// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Aggiungi qui i tuoi stili personalizzati per l'applicazione */

body {
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
  }
  
  .App {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
  
  .main-content {
    flex-grow: 1;
  }

  .container-main {
    margin-top: 80px;
  }

.header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000; /* assicura che l'header sia sopra gli altri elementi */
    /* Aggiungi qualsiasi altro stile desiderato per l'header */
  }
  
/* Stili per il footer */
.footer {
  background-color: #343a40; /* Colore di sfondo del footer */
  color: #fff; /* Colore del testo del footer */
  padding: 10px 0; /* Padding superiore e inferiore del footer */
  width: 100%;
  position: relative;
  bottom: 0;
}`, "",{"version":3,"sources":["webpack://./src/css/app.css"],"names":[],"mappings":"AAAA,gEAAgE;;AAEhE;IACI,SAAS;IACT,UAAU;IACV,8BAA8B;EAChC;;EAEA;IACE,aAAa;IACb,sBAAsB;IACtB,iBAAiB;EACnB;;EAEA;IACE,YAAY;EACd;;EAEA;IACE,gBAAgB;EAClB;;AAEF;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,WAAW;IACX,aAAa,EAAE,uDAAuD;IACtE,2DAA2D;EAC7D;;AAEF,wBAAwB;AACxB;EACE,yBAAyB,EAAE,gCAAgC;EAC3D,WAAW,EAAE,gCAAgC;EAC7C,eAAe,EAAE,6CAA6C;EAC9D,WAAW;EACX,kBAAkB;EAClB,SAAS;AACX","sourcesContent":["/* Aggiungi qui i tuoi stili personalizzati per l'applicazione */\n\nbody {\n    margin: 0;\n    padding: 0;\n    font-family: Arial, sans-serif;\n  }\n  \n  .App {\n    display: flex;\n    flex-direction: column;\n    min-height: 100vh;\n  }\n  \n  .main-content {\n    flex-grow: 1;\n  }\n\n  .container-main {\n    margin-top: 80px;\n  }\n\n.header {\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100%;\n    z-index: 1000; /* assicura che l'header sia sopra gli altri elementi */\n    /* Aggiungi qualsiasi altro stile desiderato per l'header */\n  }\n  \n/* Stili per il footer */\n.footer {\n  background-color: #343a40; /* Colore di sfondo del footer */\n  color: #fff; /* Colore del testo del footer */\n  padding: 10px 0; /* Padding superiore e inferiore del footer */\n  width: 100%;\n  position: relative;\n  bottom: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
