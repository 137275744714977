import React from "react";
import { useDrag } from "react-dnd";

const DraggableImage = ({ imageUrl }) => {
  const [{ isDragging }, drag] = useDrag({
    type: "image", // Definisci il tipo di elemento trascinabile
    item: { imageUrl },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  return (
    <div
      ref={drag}
      style={{
        opacity: isDragging ? 0.5 : 1,
        width: "100%", // Imposta le dimensioni dell'immagine trascinabile
        height: "auto",
      }}
    >
      <img
        src={imageUrl}
        alt="Pick"
        style={{ width: "100%", height: "auto" }}
      />
    </div>
  );
};

export default DraggableImage;
