import React from 'react';
import '../../components/css/switchbutton.css'

function SwitchModalita({ darkMode, onToggle }) {

  return (
    <div>
      <button
        className={`mode-button ${!darkMode ? 'active' : ''} bg-success`}
        onClick={() => onToggle("Classic")}
      >
        Modalità Classic
      </button>
      <button
        className={`mode-button ${darkMode ? 'active' : ''} bg-warning`}
        onClick={() => onToggle("Wild")}
      >
        Scegli Categoria
      </button>
      <button
        className={`mode-button ${darkMode ? 'active' : ''} bg-danger`}
        onClick={() => onToggle("Custom")}
      >
        Modalità Custom
      </button>
    </div>
  );
}

export default SwitchModalita;