import React, { useState, useEffect } from 'react';
import '../../src/components/css/steamnotifier.css';
import AdminApi from '../apis/AdminApi';
import { useParams } from 'react-router-dom';
import Cookies from 'js-cookie';

function AchievementNotifier({ onAchievementCount }) {
  let { id_sessione } = useParams();
  const [counter, setCounter] = useState(0);
  const [currentAchievementIndex, setCurrentAchievementIndex] = useState(0); // Indice dell'achievement corrente
  const [achievements, setAchievements] = useState([]);

  // tolto il counter dal div, ma posso utilizzarlo altrove (div-achievement-counter)
  const userId = Cookies.get("user_id");
  const dummySession = "XXXXXX";

  useEffect(() => {
    const fetchAchievements = async () => {
      try {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        if(id_sessione === undefined) {id_sessione = dummySession}
        const response = await AdminApi.get(`/u/${userId}/${id_sessione}/getnewchievements`);
        const numeroAchi1=  await AdminApi.get(`/u/${Cookies.get("user_id")}/${id_sessione}/conta-achievement-free`);
        const numeroAchi2 =  await AdminApi.get(`/u/${Cookies.get("user_id")}/${dummySession}/conta-achievement-free`); 

        const numeroAchi = numeroAchi1.data.achievement_non_riscattati + numeroAchi2.data.achievement_non_riscattati;
        
        setAchievements(response.data); // Assicurati che il risultato della chiamata contenga un array di achievements
        setCounter(response.data.length); // Imposta il contatore sul numero di achievements ricevuti

        await AdminApi.post(`/u/${userId}/${id_sessione}/acceptachievement`);

        // Chiamata alla funzione di callback con il numero di achievement
        if (typeof onAchievementCount === 'function') {
          onAchievementCount(numeroAchi);
        }
      } catch (error) {
        console.error('Errore durante il recupero degli achievement:', error);
      }
    };

    fetchAchievements(); // Esegui la chiamata al montaggio del componente
  }, [userId]); // Dipendenza da userId

  useEffect(() => {
    let intervalId = null;

    if (counter > 0) {
      intervalId = setInterval(() => {
        // Incrementa l'indice dell'achievement corrente e interrompi l'intervallo quando hai visualizzato tutti gli achievement
        setCurrentAchievementIndex(prevIndex => (prevIndex + 1) % counter);
        if (currentAchievementIndex === counter - 1) {
          clearInterval(intervalId);
        }
      }, 6000);
    }

    return () => clearInterval(intervalId);
  }, [counter, currentAchievementIndex]); // Dipendenze da counter e currentAchievementIndex

  return (
    <div className="ach-area">
      {/* Div per l'achievement */}
      {achievements.length > 0 && (
        <div className="achievement-popup" style={{ backgroundColor: `#000` }}>
          <div className="achievement-icon">
            <a href={`/u/${userId}/achievements`}><img alt="achievement" src={achievements[currentAchievementIndex].url_img}  style={{ width: '60px', height: '60px' }} /></a>
          </div>
          <div className="achievement-text">
            <span>Achievement Unlocked!</span><br />
            <span>{achievements[currentAchievementIndex].nome_achievement}</span> {/* Estrarre il testo dell'achievement */}
          </div>
        </div>
      )}
    </div>
  );
}

export default AchievementNotifier;
