//rafce
import React from "react";
import UserHomePage from "../../components/user/UserHomePage";
import WithAuth from "../../components/WithAuth";

const UserProfileP = () => {
  return (
    <div className="container">
      <WithAuth>
        {({ authenticated }) => (authenticated ? <UserHomePage /> : null)}
      </WithAuth>
    </div>
  );
};

export default UserProfileP;
