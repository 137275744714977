//rafce
import React from "react";
import HomeQuiz from "../../../components/HomeIndex";
import WithAuth from "../../../components/WithAuth";

const HomeQuizPage = () => {
  return (
    <div className="container">
      <WithAuth>
        {({ authenticated }) => (authenticated ? <HomeQuiz /> : null)}
      </WithAuth>
    </div>
  );
};

export default HomeQuizPage;
