//rafce
import React from "react";
import FilmDelGiorno from "../../components/misc/FilmDelGiorno";

const FilmDelGiornoP = () => {
  return (
    <div className="container">
      <FilmDelGiorno />
    </div>
  );
};

export default FilmDelGiornoP;
