import React, {useState } from 'react';
import ClassificaMensile from './ClassificaMensile';
import ClassificaPerpetua from './ClassificaPerpetua';


function ClassificaGlobale (){
    const [buttonText, setButtonText] = useState('Mostra Classifica Mensile');
    const [showClassificaMensile, setShowClassificaPerpetua] = useState(true);

    const handleClick = () => {
        if (showClassificaMensile) {
            setButtonText('Mostra Classifica Globale');
        } else {
            setButtonText('Mostra Classifica Mensile');
        }
        setShowClassificaPerpetua(!showClassificaMensile);
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <button onClick={handleClick}>{buttonText}</button>
            {showClassificaMensile ? <ClassificaMensile/> : <ClassificaPerpetua/>}
            
        </div>
    );
} 
/* {
  const [showClassificaMensile, setShowClassificaMensile] = useState(true);
  const switchTables = () => {
    setShowClassificaMensile(!showClassificaMensile);
};

return (
    <div>
        <ButtonClassifica handleClick={switchTables} />
        {showClassificaMensile ? <ClassificaMensile /> : <ClassificaPerpetua />}
        
    </div>
);
} */

export default ClassificaGlobale;