import React, { useEffect, useState } from "react";
import TmbdApi from "../../apis/TmbdApi";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Modal from "react-modal";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import DraggableImage from "../../components/misc/DraggableImage";
import { useLocation, useNavigate } from "react-router-dom";
import AdminApi from "../../apis/AdminApi";
import Cookies from "js-cookie";

const ImportRisposte = () => {
  const location = useLocation();
  const filmId = location.state?.filmId;
  const filmTitle = location.state?.filmTitle;
  const releaseDate = location.state?.releaseDate;
  const ratingMovie = location.state?.rating;
  const navigate = useNavigate();
  const userId = Cookies.get("user_id");

  const apiKey = process.env.REACT_APP_TMDB_API_KEY;
  const [backdrops, setBackdrops] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const [isErroreFilm, setIsErroreFilm] = useState(false);
  const [messaggio, setMessaggio] = useState();

  const [isHovered, setIsHovered] = useState(false);

  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
    //setModalIsOpen(true); //disabilitato
  };

  const handleConfirmImage = () => {
    //setSelectedImage(imageUrl);
    setModalIsOpen(true); //disabilitato
  };

  const handleCloseModal = () => {
    setSelectedImage(null);
    setModalIsOpen(false); //disabilitato
  };

  const backButton = () => {
    navigate(`/admin/cerca-film`);
  };

  const handleInsertFilm = async () => {
    //console.log(filmTitle)
    //console.log(selectedImage)

    //const res =
    const filmExists = await AdminApi.post(
      `/proc/admin/addrisposta/${userId}`,
      {
        title: filmTitle,
        imagePath: selectedImage,
        idTmdb: filmId,
        releaseDate: releaseDate,
        rating: ratingMovie
      }
    );

    //dato che uso una chiamata diversa per la ricerca del genere ne faccio un'altra
    //const res2 =
    await AdminApi.post(`/pull/genrefilm`, {
      filmId: filmId,
    });

    await AdminApi.post(`/pull/cast/attori`, {
      filmId: filmId,
    });

    setModalIsOpen(false);

    if (filmExists.data.result === 1) {
      //console.log(isFilmCaricato);
      navigate(`/admin/cerca-film`);
    } else {
      //console.log(isFilmCaricato);
      setIsErroreFilm(true);
      setMessaggio("Film già esistente. Scegli un altro film");
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await TmbdApi.get(
          `/movie/${filmId}/images?api_key=${apiKey}`
        );
        const baseUrl = "https://image.tmdb.org/t/p/original";
        const backdropsWithBaseUrl = res.data.backdrops.map(
          (backdrop) => `${baseUrl}${backdrop.file_path}`
        );
        setBackdrops(backdropsWithBaseUrl.slice(0, 20));

        //check achievement
        await AdminApi.post(`/u/${userId}/XXXXXX/checkachievements`);
      } catch (error) {
        console.log("Errore durante il recupero dei dati:", error);
      }
    };

    fetchData();
  }, [apiKey, filmId, userId]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 300,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: true,
  };

  // Definisci lo stile della modale
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  /****** APPUNTI x MODALE
   {modalIsOpen && 
    <Modal isOpen={modalIsOpen} onRequestClose={handleCloseModal}>
      <div className="modal-content">
        {selectedImage && <img src={selectedImage} alt="Full size" style={{ maxWidth: '100%', height: 'auto' }} />}
        <button onClick={handleCloseModal}>Chiudi</button>
      </div>
    </Modal>
    }
   */

  return (
    <DndProvider backend={HTML5Backend}>
      <>
        <Slider {...settings}>
          {backdrops.map((imageUrl, index) => (
            <div key={index} onClick={() => handleImageClick(imageUrl)}>
              <DraggableImage imageUrl={imageUrl} />
            </div>
          ))}
        </Slider>

        {/* Box per il rilascio delle immagini trascinate */}
        <div
          className="col-md-12 text-center"
          onClick={() => handleConfirmImage()}
        >
          <div className="card mt-4">
            <div className="card-body">
              <h2>{filmTitle}</h2>
              {!isErroreFilm && selectedImage && (
                // eslint-disable-next-line jsx-a11y/alt-text
                <img
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                  style={{ cursor: isHovered ? "pointer" : "default" }}
                  src={selectedImage}
                  className="img-fluid"
                />
              )}
              {messaggio && (
                  <div>
                    <p>{messaggio}</p>
                    <br />
                    <button className="btn-danger" onClick={backButton}>Torna indietro</button>
                  </div>
                )}
            </div>
          </div>
        </div>

        {/* Modal */}
        {modalIsOpen && (
          <Modal
            isOpen={modalIsOpen}
            onRequestClose={handleCloseModal}
            style={customStyles}
          >
            <div className="modal-content">
              {selectedImage && (
                  <button className="btn-primary" onClick={handleInsertFilm}>
                    Inserisci risposta
                  </button>
              )}
              <button onClick={handleCloseModal}>Chiudi</button>
            </div>
          </Modal>
        )}
      </>
    </DndProvider>
  );
};

export default ImportRisposte;
