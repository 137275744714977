// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin-menu-container {
    background-color: #f8f9fa;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .admin-menu-title {
    text-align: center;
    font-size: 1.5rem;
    margin-bottom: 20px;
  }
  
  .admin-menu-item {
    display: block;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    background-color: #e9ecef;
    text-decoration: none;
    color: #212529;
  }
  
  .admin-menu-item:hover {
    background-color: #ced4da;
  }`, "",{"version":3,"sources":["webpack://./src/components/css/adminmenu.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,aAAa;IACb,kBAAkB;IAClB,uCAAuC;EACzC;;EAEA;IACE,kBAAkB;IAClB,iBAAiB;IACjB,mBAAmB;EACrB;;EAEA;IACE,cAAc;IACd,aAAa;IACb,mBAAmB;IACnB,kBAAkB;IAClB,yBAAyB;IACzB,qBAAqB;IACrB,cAAc;EAChB;;EAEA;IACE,yBAAyB;EAC3B","sourcesContent":[".admin-menu-container {\n    background-color: #f8f9fa;\n    padding: 20px;\n    border-radius: 8px;\n    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\n  }\n  \n  .admin-menu-title {\n    text-align: center;\n    font-size: 1.5rem;\n    margin-bottom: 20px;\n  }\n  \n  .admin-menu-item {\n    display: block;\n    padding: 10px;\n    margin-bottom: 10px;\n    border-radius: 5px;\n    background-color: #e9ecef;\n    text-decoration: none;\n    color: #212529;\n  }\n  \n  .admin-menu-item:hover {\n    background-color: #ced4da;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
