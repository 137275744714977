import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import AdminApi from '../../apis/AdminApi';
import Cookies from "js-cookie";
import { useNavigate, useParams } from 'react-router-dom';
import SwitchModalita from './SwitchModalita';
import FilmFinder from '../../apis/FilmFinder';

/*
function getRandomColor() {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}
*/



function ListaCategorie() {
  const [categorieFilmClassic, setCategorieFilmClassic] = useState([]);
  const [categorieFilmWild, setCategorieFilmWild] = useState([]);
  const [categorieFilmCustom, setCategorieFilmCustom] = useState([]);

  const [isClassic, setIsClassic] = useState([]);
  const [isWild, setIsWild] = useState([]);
  const [isCustom, setIsCustom] = useState([]);

  const [randomValue, setRandomValue] = useState(0);
  const [isHovered, setIsHovered] = useState(false);

  const { id_sessione } = useParams();
  const navigate = useNavigate();

  const getModalitaValue = () => {
    switch (modalita) {
      case "Classic":
        setIsClassic(true);
        setIsWild(false);
        setIsCustom(false);
        return 1;
      case "Wild":
        setIsClassic(false);
        setIsWild(true);
        setIsCustom(false);
        return 2;
      case "Custom":
        setIsClassic(false);
        setIsWild(false);
        setIsCustom(true);
          return 3;
      default:
        return 0; // Valore predefinito nel caso in cui la modalità non corrisponda a nessun caso
    }
  };

  const onHandleClickButton = async (id_categoria) => {

    await FilmFinder.post(`/proc/setcategoria/${id_sessione}`, 
      {idCategoria: id_categoria}
    );

    await FilmFinder.post(`/proc/setmodalita/${id_sessione}`, 
      {idModalita: getModalitaValue(modalita)}
    );

    //Cookies.set("id_modalita", );

    navigate(`/quiz/${Cookies.get("user_id")}/${id_sessione}/${id_categoria}/1`)
    //console.log("Categoria cliccata:", id_categoria);
  
  }

  const [modalita, setModalita] = useState("");

  const toggleModalita = (nuovaModalita) => {
    setModalita(nuovaModalita);
  };

  useEffect(() => {
    const fetchFilmList = async () => {
      try {

        //classic
        const classic = await AdminApi.get(`/g/listacategorie/classic`);

        setCategorieFilmClassic(classic.data);

        const wild = await AdminApi.get(`/g/listacategorie/wild`);

        setCategorieFilmWild(wild.data);

        const custom = await AdminApi.get(`/g/listacategorie/custom`);

        setCategorieFilmCustom(custom.data);

        if(isClassic) {
          
          const randomIndex = Math.floor(Math.random() * classic.data.length);
          setRandomValue(classic.data[randomIndex].id_categoria)
        
        };

        if(isWild) {

          const randomIndex = Math.floor(Math.random() * wild.data.length);
          setRandomValue(wild.data[randomIndex].id_categoria)
        
        };

        if(isCustom) {

          const randomIndex = Math.floor(Math.random() * custom.data.length);
          setRandomValue(custom.data[randomIndex].id_categoria)};

      } catch (error) {
        console.error('Errore durante il recupero della lista film:', error);
      }
    };

    fetchFilmList();
  }, [isClassic, isWild, isCustom]);

  return (
    <div className="container">
      <h2 className="btn-info">Scegli una categoria!</h2>
      <div className="text-center"><h1>{ modalita === "" && <div> Scegli una categoria </div>} { modalita !== "" && <div> Hai scelto {modalita}!</div>}</h1><br/>
      <SwitchModalita darkMode={modalita} onToggle={toggleModalita} />
      </div>
      { (modalita === "Classic" || modalita === "Wild" || modalita === "Custom") && <div className="row">
          <div className="d-flex justify-content-center align-items-center">
            <Card className="border-0 shadow-sm" style={{  }}>
            
              <div style={{ height: '75px', backgroundColor: '#eee' }} className="d-flex justify-content-center align-items-center">
              <Card.Img src="https://download.cjgames.it/repository/cinezor/img/categories/icons8-dice-96.png" 
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
              onClick={() => onHandleClickButton(randomValue)}
              style={{ cursor: isHovered ? 'pointer' : 'default', width: '64px', height: '64px', margin: 'auto' }} /><br/>
                      </div>
              <div style={{ height: '75px', backgroundColor: '#eee' }} className="d-flex justify-content-center align-items-center"><Card.Title style={{ textAlign: 'center' }} className ="btn-danger">Random</Card.Title></div>
              <Card.Body>
                
                <Card.Text>
                  Numero di film: ∞
                </Card.Text>
              </Card.Body>
            </Card>
          </div> 
      </div> }
      { modalita === "Classic" && 
      <div className="row">
        {categorieFilmClassic.map((categoria, index) => (
          <div className="col-md-4 mb-4" key={categoria.id_categoria}>
            <Card className="border-0 shadow-sm" style={{  }}>
            
              <div style={{ height: '75px', backgroundColor: '#eee' }} className="d-flex justify-content-center align-items-center">
              <Card.Img 
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
              onClick={() => onHandleClickButton(categoria.id_categoria)} 
              src={categoria.url_img} 
              style={{ cursor: isHovered ? 'pointer' : 'default', width: '64px', height: '64px', margin: 'auto' }} /><br/>
              </div>
              <div style={{ height: '75px', backgroundColor: '#eee' }} className="d-flex justify-content-center align-items-center"><Card.Title style={{ textAlign: 'center' }} className="btn-warning">{categoria.descr_categoria}</Card.Title></div>
              <Card.Body>
                
                <Card.Text>
                  Numero di film: <b>{categoria.numero_film}</b>
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
        ))}
      </div>}
      { modalita === "Wild" && 
      <div className="row">
        {categorieFilmWild.map((categoria, index) => (
          <div className="col-md-4 mb-4" key={categoria.id_categoria}>
            <Card className="border-0 shadow-sm" style={{  }}>
            
              <div style={{ height: '75px', backgroundColor: '#eee' }} className="d-flex justify-content-center align-items-center">
              <Card.Img 
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
              onClick={() => onHandleClickButton(categoria.id_categoria)} 
              src={categoria.url_img} 
              style={{ cursor: isHovered ? 'pointer' : 'default', width: '64px', height: '64px', margin: 'auto' }} /><br/>
              </div>
              <div style={{ height: '75px', backgroundColor: '#eee' }} className="d-flex justify-content-center align-items-center"><Card.Title style={{ textAlign: 'center' }} className="btn-warning">{categoria.descr_categoria}</Card.Title></div>
              <Card.Body>
                
                <Card.Text>
                  Numero di film: <b>{categoria.numero_film}</b>
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
        ))}
      </div>
    }
    { modalita === "Custom" && 
      <div className="row">
        {categorieFilmCustom.map((categoria, index) => (
          <div className="col-md-4 mb-4" key={categoria.id_categoria}>
            <Card className="border-0 shadow-sm" style={{  }}>
            
              <div style={{ height: '75px', backgroundColor: '#eee' }} className="d-flex justify-content-center align-items-center">
              <Card.Img 
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
              onClick={() => onHandleClickButton(categoria.id_categoria)} 
              src={categoria.url_img} 
              style={{ cursor: isHovered ? 'pointer' : 'default', width: '64px', height: '64px', margin: 'auto' }} /><br/>
              </div>
              <div style={{ height: '75px', backgroundColor: '#eee' }} className="d-flex justify-content-center align-items-center"><Card.Title style={{ textAlign: 'center' }} className="btn-warning">{categoria.descr_categoria}</Card.Title></div>
              <Card.Body>
                
                <Card.Text>
                  Numero di film: <b>{categoria.numero_film}</b>
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
        ))}
      </div>
    }
    </div>
  );
}

export default ListaCategorie;
