import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AdminApi from "../../apis/AdminApi";
import FilmFinder from "../../apis/FilmFinder";

const CheckChiusura = () => {
  const { id_sessione } = useParams();
  const { userId } = useParams();

  const navigate = useNavigate();
  const [flagChiudi, setFlagChiudi] = useState();
  const [categoria, setCategoria] = useState("");

  const onClickTornaQuiz = () => {
    console.log(categoria);
    navigate(`/quiz/${userId}/${id_sessione}/${categoria}/1`);
  };

  useEffect(() => {
    const loadFlagChiudi = async () => {
      try {
        const getCategoria = await FilmFinder.get(
          `/proc/getcategoria/${id_sessione}`
        );

        setCategoria(getCategoria.data.result.id_categoria);

        const canChiudiSessione = await AdminApi.get(
          `/api/v1/quiz/checknrisposte/${id_sessione}`
        );

        setFlagChiudi(parseInt(canChiudiSessione.data.result.check_n_risposte));

        if (flagChiudi >= 10) {
          await AdminApi.post(`/api/v1/quiz/chiudisessione/${id_sessione}`);

          const isRedeemed = await AdminApi.get(`/g/${id_sessione}/isredeemed`);
          const numFlag = parseInt(parseInt(isRedeemed.data.is_redeemed));
          //console.log(numFlag);

          if (numFlag === 0) {

            // aggiungi 500 punti
            await AdminApi.post(
              `/u/${userId}/${id_sessione}/checkachievements`
            );
            await AdminApi.post(`/g/${id_sessione}/redeemsessione`);
            await AdminApi.post(`/u/add_tapcoin/${userId}/500`);
          }

          navigate(`/quiz/${userId}/${id_sessione}/fine`);
        }
      } catch (error) {
        console.log("errore");
      }
    };

    loadFlagChiudi();
  }, [id_sessione, flagChiudi, navigate, userId]);

  return (
    <div className="container d-flex justify-content-center">
        {flagChiudi < 10 && <div>
         <p>Ti mancano {10-flagChiudi} risposte...</p>
          <div className="text-center">
            <button onClick={onClickTornaQuiz} className="btn btn-danger">
              Torna al quiz
            </button>
          </div> 
        </div> }
    </div>
  );
};

export default CheckChiusura;
