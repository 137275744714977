// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .button-help {
    display: flex;
    align-items: center;

    border: none;
    background-color: #007bff;
    color: white;
    font-size: 16px;
    cursor: pointer;
    border-radius: 5px;
    margin-bottom: 80px;
  }
  
  .custom-button {
    margin-right:0px;
    margin-bottom: 60px;
    width: 20px;
    height: 20px;
  }

  .custom-button::before {
    content: '';
    display: block;
    background-image: url('https://download.cjgames.it/repository/cinezor/img/helps/Help.png');
    background-size: contain;
    background-repeat: no-repeat;
    width: 200px;
    height: 80px;
    position: absolute;
    left: 10px;
  } */

  
.help-button {
    display: inline-flex;
    align-items: stretch;
    justify-content:center;
    padding: 12px 40px;
    border: none;
    background-image: url('https://download.cjgames.it/repository/cinezor/img/helps/Help.png');
    background-size: cover;
    background-position: center;
    color: rgb(0, 0, 0);
    font-size: 15px;
    cursor: pointer;
    border-radius: 5px;
    width: 160px;  /*regolare larghezza*/
    height: 80px;  /*regolare altezza*/
    text-align: center;
    position: relative;
    margin-bottom: 10px;   
    line-height: 16px;
    
   
  }
  
  `, "",{"version":3,"sources":["webpack://./src/components/css/ButtonHelp.css"],"names":[],"mappings":"AAAA;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;KA8BK;;;AAGL;IACI,oBAAoB;IACpB,oBAAoB;IACpB,sBAAsB;IACtB,kBAAkB;IAClB,YAAY;IACZ,0FAA0F;IAC1F,sBAAsB;IACtB,2BAA2B;IAC3B,mBAAmB;IACnB,eAAe;IACf,eAAe;IACf,kBAAkB;IAClB,YAAY,GAAG,qBAAqB;IACpC,YAAY,GAAG,mBAAmB;IAClC,kBAAkB;IAClB,kBAAkB;IAClB,mBAAmB;IACnB,iBAAiB;;;EAGnB","sourcesContent":["/* .button-help {\n    display: flex;\n    align-items: center;\n\n    border: none;\n    background-color: #007bff;\n    color: white;\n    font-size: 16px;\n    cursor: pointer;\n    border-radius: 5px;\n    margin-bottom: 80px;\n  }\n  \n  .custom-button {\n    margin-right:0px;\n    margin-bottom: 60px;\n    width: 20px;\n    height: 20px;\n  }\n\n  .custom-button::before {\n    content: '';\n    display: block;\n    background-image: url('https://download.cjgames.it/repository/cinezor/img/helps/Help.png');\n    background-size: contain;\n    background-repeat: no-repeat;\n    width: 200px;\n    height: 80px;\n    position: absolute;\n    left: 10px;\n  } */\n\n  \n.help-button {\n    display: inline-flex;\n    align-items: stretch;\n    justify-content:center;\n    padding: 12px 40px;\n    border: none;\n    background-image: url('https://download.cjgames.it/repository/cinezor/img/helps/Help.png');\n    background-size: cover;\n    background-position: center;\n    color: rgb(0, 0, 0);\n    font-size: 15px;\n    cursor: pointer;\n    border-radius: 5px;\n    width: 160px;  /*regolare larghezza*/\n    height: 80px;  /*regolare altezza*/\n    text-align: center;\n    position: relative;\n    margin-bottom: 10px;   \n    line-height: 16px;\n    \n   \n  }\n  \n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
