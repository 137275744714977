import React, { useEffect, useState } from 'react';
import '../css/user.css';
import profileImage from '../../images/avatar.jpg';
import CardPage from './CardCollection';
import { useNavigate } from 'react-router-dom';
import AdminApi from '../../apis/AdminApi';
import FriendPendingRequests from './FriendsPendingRequests';
import FriendsList from './FriendsList';
import Cookies from "js-cookie";

const UserProfile = ({ userId }) => {
	const navigate = useNavigate();
	const [nickname, setNickname] = useState();
	const [refreshList, setRefreshList] = useState(false);

	const handleFilmCaricati = () => {
		navigate(`/u/${userId}/movielist`)
	}

	const handleLastSessions = () => {
		navigate(`/u/${userId}/sessions`)
	}

	const handleUserAchievements = () => {
		navigate(`/u/${userId}/achievements`)
	}

	const handleCollections = () => {
		navigate(`/u/${userId}/collections`)
	}

	const handleAddFriend = (idUtenteFollow) => {
		//console.log(idUtenteFollow);
		navigate(`/u/addfriend/${Cookies.get("user_id")}/`, { state: { idUtenteAdd: idUtenteFollow } })
	}

	useEffect(() => {
		const fetchFilmList = async () => {
		  try {
			const token = localStorage.getItem('token');
			//console.log(token);

			const response = await AdminApi.get(`/u/${userId}/getinfoplayer`, {
			headers: {
				Authorization: `Bearer ${token}`
			}});

			setNickname(response.data.nickname);
			//setRank(response.data.rank);

		  } catch (error) {
			console.error('Errore durante il recupero delle transazioni:', error);
	
			switch (error.response.status) {
			  case 401:
				navigate('/e/401');
				break;
			  case 403:
				navigate('/e/403');
				break;
			  case 500:
				navigate('/e/500');
				break;
			  default:
				console.error('Errore durante il recupero delle transazioni:', error);
			}
		  }
		};
	
		fetchFilmList();
	  }, [userId, navigate]); // Re-fetch film list when userId changes

	  /*
	  <div className="profile-usermenu">
					<ul className="nav-item">
						<li className="active">
							<a href="#">
							<i className="glyphicon glyphicon-home"></i>
							Overview </a>
						</li>
						<li>
							<a href="#">
							<i className="glyphicon glyphicon-user"></i>
							Account Settings </a>
						</li>
						<li>
							<a href="#" target="_blank">
							<i className="glyphicon glyphicon-ok"></i>
							Tasks </a>
						</li>
						<li>
							<a href="#">
							<i className="glyphicon glyphicon-flag"></i>
							Help </a>
						</li>
					</ul>
				</div>
	*/

  return (
    <div className="container">
    <div className="row profile">
		<div className="col-md-3">
			<div className="profile-sidebar">
				<div className="profile-userpic text-center">
					<img src={profileImage} className="img-responsive" alt="" />
				</div>
		
				<div className="profile-usertitle">
					<div className="profile-usertitle-name">
						{nickname}
					</div>
					<div className="profile-usertitle-job">
						(Rank)
					</div>
				</div>

				<div className="profile-userbuttons">
					<button type="button" className="btn btn-danger btn-sm" onClick={() => handleAddFriend(userId)}>➕ Add Friend</button>
					{/*<!-- <button type="button" className="btn btn-danger btn-sm">Message</button> !-->*/}
				</div>

				
			</div>
		</div>
		<div className="col-md-9">
			<div className="profile-content">
			   <FriendPendingRequests idUtente = {userId} refreshList={refreshList} setRefreshList={setRefreshList}/>
            </div>
            <div className="profile-content">
			   <button onClick={handleFilmCaricati} className="btn-success">Film caricati</button>
            </div>
            <div className="profile-content">
				<button onClick={handleCollections} className="btn-success">Carte attori</button>
            </div>
            <div className="profile-content">
				<button onClick={handleLastSessions} className="btn-success">Ultime sessioni</button>
            </div>
            <div className="profile-content">
				<button onClick={handleUserAchievements} className="btn-success">Achievements</button>
            </div>
		</div>
	<FriendsList idUtente = {userId} refreshList = {refreshList}/>
    <CardPage /></div>
	
    </div>
  );
};

export default UserProfile;
