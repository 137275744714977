import React, { useState } from 'react';
import { DndContext, closestCenter, DragOverlay } from '@dnd-kit/core';
import { SortableContext, rectSortingStrategy, arrayMove } from '@dnd-kit/sortable';
import Droppable from './Droppable';
import SortableItem3 from './SortableItem3';

const Example3 = () => {
  const [images, setImages] = useState([
    { id: '1', src: 'https://image.tmdb.org/t/p/original/8VKv0silVVUvpYblHqNNyRBs3Wu.jpg' },
    { id: '2', src: 'https://image.tmdb.org/t/p/original/tExIq4dEKaEskhnaauuDwN9HR0u.jpg' },
    { id: '3', src: 'https://image.tmdb.org/t/p/original/iIKDEYVsA2B5nRjFw347dSlYpq0.jpg' }
  ]);

  const [titles, setTitles] = useState([
    { id: '4', title: 'Blade Runner', image: null },
    { id: '5', title: 'Mr Robot', image: null },
    { id: '6', title: 'Il padrino', image: null }
  ]);

  const [activeId, setActiveId] = useState(null);

  const handleDragStart = (event) => {
    setActiveId(event.active.id);
  };

  const handleDragEnd = (event) => {
    const { active, over } = event;
    setActiveId(null);

    if (!over) return;

    const activeContainer = active.data.current.sortable.containerId;
    const overContainer = over.data.current?.sortable?.containerId || over.id;

    if (activeContainer === 'images' && overContainer === 'titles') {
      const activeItem = images.find(item => item.id === active.id);
      if (activeItem) {
        const overIndex = titles.findIndex(item => item.id === over.id);
        if (overIndex !== -1) {
          setTitles((titles) => {
            const newTitles = [...titles];
            newTitles[overIndex].image = activeItem;
            return newTitles;
          });

          setImages((images) => images.filter(item => item.id !== active.id));
        }
      }
    } else if (activeContainer === 'titles' && overContainer === 'titles') {
      const oldIndex = titles.findIndex(item => item.id === active.id);
      const newIndex = titles.findIndex(item => item.id === over.id);
      if (oldIndex !== -1 && newIndex !== -1) {
        setTitles((items) => arrayMove(items, oldIndex, newIndex));
      }
    } else if (activeContainer === 'titles' && overContainer === 'images') {
      const activeItemIndex = titles.findIndex(item => item.image?.id === active.id);
      if (activeItemIndex !== -1) {
        setImages((images) => [...images, titles[activeItemIndex].image]);
        setTitles((titles) => {
          const newTitles = [...titles];
          newTitles[activeItemIndex].image = null;
          return newTitles;
        });
      }
    } else if (activeContainer === 'titles' && overContainer === 'titles') {
      const oldIndex = titles.findIndex(item => item.image?.id === active.id);
      const newIndex = titles.findIndex(item => item.id === over.id);
      if (oldIndex !== -1 && newIndex !== -1) {
        setTitles((items) => {
          const newTitles = [...items];
          const [movedItem] = newTitles.splice(oldIndex, 1);
          newTitles.splice(newIndex, 0, movedItem);
          return newTitles;
        });
      }
    }
  };

  return (
    <DndContext
      collisionDetection={closestCenter}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
    >
      <div style={{ display: 'flex', justifyContent: 'space-around' }}>
        <Droppable id="images">
          <SortableContext id="images" items={images} strategy={rectSortingStrategy}>
            {images.map((item) => (
              <SortableItem3 key={item.id} id={item.id} src={item.src} />
            ))}
          </SortableContext>
        </Droppable>

        <Droppable id="titles">
          <SortableContext id="titles" items={titles} strategy={rectSortingStrategy}>
            {titles.map((item) => (
              <SortableItem3 key={item.id} id={item.id} title={item.title} image={item.image} />
            ))}
          </SortableContext>
        </Droppable>
      </div>
      <DragOverlay>
        {activeId ? (
          <SortableItem3
            id={activeId}
            src={
              images.find(item => item.id === activeId)?.src ||
              titles.find(item => item.image && item.image.id === activeId)?.image.src
            }
          />
        ) : null}
      </DragOverlay>
    </DndContext>
  );
};

export default Example3;
