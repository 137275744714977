import React, { useEffect } from "react";
import { useCountdown } from "../../context/CountdownContext";

function Countdown() {
  const { minutes, seconds, setMinutes, setSeconds } = useCountdown();

  useEffect(() => {
    const intervalId = setInterval(() => {
      setSeconds((prevSeconds) => {
        if (prevSeconds <= 0) {
          if (minutes <= 0) {
            clearInterval(intervalId);
            return 0;
          } else {
            setMinutes((prevMinutes) => prevMinutes - 1);
            return 59;
          }
        }

        //const tempMin = minutes;
        //console.log(tempMin);

        if (minutes === 0 && seconds === 0) {
          // Logica per quando il tempo è esaurito
          console.log("Tempo esaurito");
          
        }

        return prevSeconds - 1;
      });
      
    }, 1000);

    return () => clearInterval(intervalId);
  }, [seconds, minutes, setMinutes, setSeconds]);

  // Calcola la percentuale del tempo trascorso rispetto al tempo totale
  const initialTotalSeconds = (minutes + seconds);
  const totalSeconds = minutes * 60 + seconds;
  const percentComplete = (initialTotalSeconds - totalSeconds) / initialTotalSeconds * 100;

  // Stile dinamico per il box del countdown
  const countdownStyle = {
    width: "100%",
    border: "1px solid #000",
    padding: "10px",
    margin: "3px",
    textAlign: "center",
    backgroundColor:
      percentComplete > 50 ? "red" : percentComplete > 25 ? "yellow" : "green",
    color: "white",
  };

  return (
    <div>
      <div className="row" style={countdownStyle}>
        Tempo rimanente: {minutes} minuti {seconds} secondi
      </div>
    </div>
  );
}

export default Countdown;
