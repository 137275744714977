import React, { useEffect, useState } from "react";
import FilmFinder from "../../apis/FilmFinder";
import { useParams } from "react-router-dom";
import "../../components/css/globals.css";
import { FaCheck, FaTimes } from "react-icons/fa";
import AdminApi from "../../apis/AdminApi";
import AchievementNotifier from "../AchievementNotifier";
import Cookies from "js-cookie";


const RisultatiQuiz = () => {
  const [risposte, setRisposte] = useState([]);
  const { id_sessione } = useParams();
  const [isQuizEnded, setQuizEnded] = useState(2);
  const [categoria, setCategoria] = useState();
  const [achievementCount, setAchievementCount] = useState(0);
  const [showAchievement, setShowAchievement] = useState(true);

  useEffect(() => {

    const fetchData = async () => {
      const isQuizEnded = await AdminApi.get(
        `/api/v1/quiz/isquizended/${id_sessione}`
      );

      const intQuizEnded = parseInt(
        isQuizEnded.data.result.check_sessione_conclusa
      );

      
      setQuizEnded(intQuizEnded);

      //console.log(intQuizEnded);

      if (intQuizEnded === 1) {

        /* check achievements */
        await AdminApi.post(`/u/${Cookies.get("user_id")}/${id_sessione}/checkachievements`);
        await AdminApi.get(`/u/${Cookies.get("user_id")}/getinfo`);

        if (achievementCount > 0) { // Attiva il timeout solo se ci sono achievement
          // Calcola la durata del timeout basata sul numero di achievement
          const timeoutDuration = 5500 * (achievementCount); // Incrementa il numero di achievement di 1 perché il primo avviene immediatamente
          const timeout = setTimeout(() => {
            setShowAchievement(false);
          }, timeoutDuration);

          return () => clearTimeout(timeout); // Pulizia dell'effetto quando il componente si smonta
        }
        /* fine check achievement */

        try {
          const res = await FilmFinder.get(
            `/proc/film/elencorisposte/${id_sessione}`
          );

          const updatedRisposte = await Promise.all(
            res.data.result.map(async (risposta) => {

              const resTitoloSoluzione = await FilmFinder.get(
                `/proc/film/getfilmbyid/${risposta.id_soluzione}`
              );

              const resTitoloRisposta = await FilmFinder.get(
                `/proc/film/getfilmbyid/${risposta.id_risposta}`
              );

              const getCategoria = await FilmFinder.get(
                `/proc/getcategoria/${id_sessione}`
              );

              setCategoria(getCategoria.data.result.id_categoria);

              return {
                ...risposta,
                nome_film_soluzione: resTitoloSoluzione.data.result.titolo,
                nome_film_risposta: resTitoloRisposta.data.result.titolo,
              };
            })
          );
          setRisposte(updatedRisposte);
        } catch (error) {
          console.log("Errore durante il recupero dei dati:", error);
        }
      }
    };

    fetchData();
  }, [achievementCount, id_sessione, isQuizEnded]);

  return (
    <div>
      {showAchievement && <AchievementNotifier onAchievementCount={setAchievementCount} />}
      {isQuizEnded === 1 &&
        <div>
        <table className="table table-bordered">
          <thead className="thead-dark">
            <tr>
              <th scope="col">Nome Film Soluzione</th>
              <th scope="col">Soluzione del film</th>
              <th scope="col">La tua risposta</th>
              <th scope="col">Punteggio</th>
              <th scope="col">Aiuti</th>
            </tr>
          </thead>
          <tbody>
            {risposte.map((risposta, index) => (
              <tr key={index}>
                <td>
                  <a href={`/quiz/${risposta.id_utente}/${id_sessione}/${categoria}/${index + 1}/closed`}>
                    Domanda {index + 1}{" "}
                  </a>
                  {risposta.id_soluzione === risposta.id_risposta ? (
                    <FaCheck style={{ color: "green" }} />
                  ) : (
                    <FaTimes style={{ color: "red" }} />
                  )}
                </td>
                <td>{risposta.nome_film_soluzione}</td>
                <td>{risposta.nome_film_risposta}</td>
                <td>{risposta.punti_oscar*10}</td>
                <td>{risposta.n_aiuti_usati}</td>
              </tr>
            ))}
          </tbody>
        </table>
        </div>}

        {isQuizEnded === 0 && <div>Non hai completato la sessione</div> }
    </div>
  );
};

export default RisultatiQuiz;
