import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import AdminApi from '../../apis/AdminApi';
import '../../components/css/actorcard.css'
import { useParams } from 'react-router-dom';
//import '../../components/js/script.js'

/*
function getRandomColor() {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}
*/

const getRarityClass = (rarita) => {
  switch (rarita) {
    case "Legendary":
      return "legendary";
    case "Epic":
      return "epic";
    case "Rare":
      return "rare";
    case "Common":
      return "common";
    case "Uncommon":
        return "uncommon";
    // Aggiungi altri casi a seconda delle rarità necessarie
    default:
      return "";
  }
};

const getRarityCss = (rarita) => {
  switch (rarita) {
    case "Legendary":
      return "pika";
    case "Epic":
      return "mewtwo";
    case "Rare":
      return "eevee";
    case "Common":
      return "charizard";
      case "Uncommon":
        return "charizard";
    // Aggiungi altri casi a seconda delle rarità necessarie
    default:
      return "";
  }
};

function CollezioneCarte() {
  const [attori, setAttori] = useState([]);
  const [isHovered, setIsHovered] = useState(false);

  const { userId } = useParams();
  const [nickname, setNickname] = useState("");

  useEffect(() => {
    const fetchFilmList = async () => {
      try {
        const response = await AdminApi.get(`/u/${userId}/getcollections`);
        console.log(response.data)

        setAttori(response.data);

        const nick = await AdminApi.get(`/u/${userId}/getinfo`);
        setNickname(nick.data.nickname);

      } catch (error) {
        console.error('Errore durante il recupero della lista film:', error);
      }
    };

    fetchFilmList();
  }, [userId]);

  return (
    <div className="container">
      <h2 className="text-center">La collezione di {nickname}</h2>
      <div className="row">
        {attori.map((attore, index) => (
          
          <div className="col-custom mb-4" key={index}>
            <Card className={`special ${getRarityClass(attore.descr_rarita)} ${getRarityCss(attore.descr_rarita)}`} style={{  }}>
            {<div className='qta-carte'>{attore.qta_attore>0 && attore.qta_attore<2 &&  <p>({attore.qta_attore} copia)</p>}{attore.qta_attore>=2 && <p>({attore.qta_attore} copie)</p>} </div>}
            <div className="d-flex justify-content-center align-items-center">
                <Card.Title style={{ textAlign: 'center' }} className="">{attore.nome_attore} <br/>({attore.descr_rarita})</Card.Title></div>
              
              <div style={{ }} className="d-flex justify-content-center align-items-center">
              <Card.Img 
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
              src={attore.immagine} 
              style={{ width: '95%', cursor: isHovered ? 'pointer' : 'default', margin: 'auto' }} /><br/>
              </div>
              
            </Card>
          </div>
        ))}
      </div>
    </div>
  );
}

export default CollezioneCarte;
