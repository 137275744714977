import React from "react";

const Err401 = () => {
  return (
    <div>
      <h3 className="font-weight-light text-center">
       401 Unauthorized
      </h3>
    </div>
  );
};

export default Err401;
