import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import FilmFinder from "../apis/FilmFinder";
import Cookies from "js-cookie";
import AdminApi from "../apis/AdminApi";
import AchievementNotifier from "./AchievementNotifier";
import Features from "./user/Features";
import "../../src/components/css/oldfashiontimer.css"

const Header = () => {
  let navigate = useNavigate();

  const [showAchievement, setShowAchievement] = useState(true); // Stato per gestire la visibilità dell'AchievementNotifier
  const [achievementCount, setAchievementCount] = useState(0);

  const iniziaQuizFilm = async () => {
    const response = await FilmFinder.get(`/proc/genid/${Cookies.get("user_id")}`);

    navigate(`/quiz/${Cookies.get("user_id")}/${response.data.result.uuid}/listacategorie`);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        await AdminApi.post(`/u/${Cookies.get("user_id")}/XXXXXX/checkachievements`);
        await AdminApi.get(`/u/${Cookies.get("user_id")}/getinfo`);

        if (achievementCount > 0) { // Attiva il timeout solo se ci sono achievement
          // Calcola la durata del timeout basata sul numero di achievement
          const timeoutDuration = 5500 * (achievementCount); // Incrementa il numero di achievement di 1 perché il primo avviene immediatamente
          const timeout = setTimeout(() => {
            setShowAchievement(false);
          }, timeoutDuration);

          return () => clearTimeout(timeout); // Pulizia dell'effetto quando il componente si smonta
        }
      } catch (error) {
        console.log(error)
        localStorage.removeItem('token');
        navigate(`/login`)
      }
    };

    fetchData();

  }, [achievementCount, navigate]);

  return (
    <div className="">
      <div className="container mt-5">
        <h3 className="text-center"><p>Benvenuto {Cookies.get("nickname")}!</p></h3>
        <div className="row">
          <div className="col-md-6 offset-md-3">
            <div className="card">
              <div className="card-header bg-primary text-white">
                <h2 className="text-center">Cinezor Quiz Game</h2>
              </div>
              <div className="card-body">
                {showAchievement && <AchievementNotifier onAchievementCount={setAchievementCount} />}
                <div className="text-center">
                  <a href="/#"><img onClick={(e) => {
                    e.preventDefault();
                    iniziaQuizFilm();
                  }} src="https://download.cjgames.it/repository/cinezor/img/cards/quiz.png" className="card-img-top w-50" alt="Placeholder" /></a>
                  <p></p>
                  <button
                    className="btn btn-success btn-lg mb-2 btn-block"
                    onClick={(e) => {
                      e.preventDefault();
                      iniziaQuizFilm();
                    }}
                    data-toggle="modal"
                    data-target="#modal1"
                  >
                    Inizia Quiz Film<br/>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
     <Features/>
    </div>
  );
};

export default Header;
