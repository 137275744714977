//rafce
import React from "react";
import ImportRisposte from "../../components/admin/ImportRisposte";
import WithAuth from "../../components/WithAuth";

const ImportaRisposteP = () => {
  return (
    <div className="container">
      <WithAuth>
        {({ authenticated }) => (authenticated ? <ImportRisposte /> : null)}
      </WithAuth>
    </div>
  );
};

export default ImportaRisposteP;
