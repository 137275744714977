import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Table } from 'react-bootstrap';
import AdminApi from '../../apis/AdminApi';

function LastTransactions() {
  const { userId } = useParams();
  const [sessions, setSessions] = useState([]);
  const [infoPlayer, setInfoPlayer] = useState("");

  const navigate = useNavigate();
  
  useEffect(() => {
    
    const fetchTransactions = async () => {
      try {
        // Effettua la richiesta per ottenere le transazioni
        const token = localStorage.getItem('token');
        //console.log(token);

        // Effettua la richiesta per ottenere le informazioni del giocatore
        const getInfoPlayer = await AdminApi.get(`/u/${userId}/getinfoplayer`, {
          headers: {
            Authorization: `Bearer ${token}`
          }});
        setInfoPlayer(getInfoPlayer.data);

        const response = await AdminApi.get(`/u/cercatransazioni/TAPCOIN/${userId}`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });

        setSessions(response.data);
      } catch (error) {
        console.error('Errore durante il recupero delle transazioni:', error);

        switch (error.response.status) {
          case 401:
            navigate('/e/401');
            break;
          case 403:
            navigate('/e/403');
            break;
          case 500:
            navigate('/e/500');
            break;
          default:
            console.error('Errore durante il recupero delle transazioni:', error);
        }
      
      }
    };

    fetchTransactions();
  }, [userId, navigate]);

  return (
    <div className="container">
      <h2>Le ultime transazioni di {infoPlayer.nickname}</h2>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th style={{ width: '50%' }}>Azione</th>
            <th style={{ width: '10%' }}>Costo</th>
            <th style={{ width: '40%' }}>Data</th>
          </tr>
        </thead>
        <tbody>
          {sessions.map((session, index) => (
            <tr key={index}>
              <td>Riscatto Carta</td>
              <td>{session.valore}</td>
              <td>{`${session.data_inserimento.substring(8,10)}/${session.data_inserimento.substring(5,7)}/${session.data_inserimento.substr(0,4)} ${session.data_inserimento.substring(11,19)}`}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
}

export default LastTransactions;
