import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Table } from 'react-bootstrap';
import AdminApi from '../../apis/AdminApi';

function FriendsList({ idUtente, refreshList }) {
  //const { userId } = useParams();
  const [listaUtenti, setListaUtenti] = useState([]);
  const [mostraRequest, setMostraRequest] = useState(false);

  const navigate = useNavigate();

  const token = localStorage.getItem('token');
  //console.log(token);

  useEffect(() => {
    
    const fetchUtenti = async () => {
      try {
        //console.log(token);

        // Effettua la richiesta per ottenere le informazioni del giocatore
        //console.log(idUtente);
        //console.log(idUtente);

        const listaUtenti = await AdminApi.get(`/g/listaamici/${idUtente}`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });

        setListaUtenti(listaUtenti.data);

        setMostraRequest(true);
        
      } catch (error) {
        console.error('Errore durante il recupero delle transazioni:', error);

        switch (error.response.status) {
          case 401:
            setMostraRequest(false);
            break;
          case 403:
            setMostraRequest(false);
            break;
          case 500:
            setMostraRequest(false);
            break;
          default:
            console.error('Errore durante il recupero delle transazioni:', error);
        }
      
      }
    };

    //console.log(refreshList);

    fetchUtenti();

  }, [refreshList, idUtente, navigate, token]);

  

  return (
    <div className="container">
      {mostraRequest && <div><h2>Lista Amici</h2>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th style={{ width: '5%' }}>UserId</th>
            <th style={{ width: '25%' }}>Username</th>
          </tr>
        </thead>
        <tbody>
        {listaUtenti.map((utente, index) => (
          <tr key={index}>
            <td>{utente.id}</td>
            <td><b><a href={`/u/${utente.id_from_nickname}`}>{utente.from_nickname}</a></b></td>
          </tr>
        ))}
      </tbody>

      </Table> 
      </div>}
    </div>
  );
}

export default FriendsList;
