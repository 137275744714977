import React from "react";

const RatingStar = ({ rating }) => {
  const stars = [];

  for (let i = 1; i <= 10; i++) {
    if (i <= rating) {
      // uso font-awesome
      stars.push(<i key={i} className="fas fa-star text-warning"></i>);
    } else if (i === Math.ceil(rating) && !Number.isInteger(rating)) {
      stars.push(<i key={i} className="fas fa-star-half-alt text-warning"></i>);
    } else {
      stars.push(<i key={i} className="far fa-star text-warning"></i>);
    }
  }

  return <>{stars}</>;
};

export default RatingStar;
