import React from 'react';
import { useDroppable } from '@dnd-kit/core';

const Droppable = ({ id, children }) => {
  const { setNodeRef } = useDroppable({ id });

  const style = {
    backgroundColor: '#e0e0e0',
    padding: '16px',
    borderRadius: '4px',
    width: '200px',
    minHeight: '200px',
  };

  return (
    <div ref={setNodeRef} style={style}>
      {children}
    </div>
  );
};

export default Droppable;
