import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom'; // Importa useParams per ottenere i parametri dall'URL
import { Table } from 'react-bootstrap';
import AdminApi from '../../apis/AdminApi'; // Supponiamo che AdminApi sia un'istanza di Axios o di un altro client per effettuare richieste HTTP

function FilmList() {
  const { userId } = useParams(); // Ottieni l'ID utente dalla URL
  const [filmList, setFilmList] = useState([]);
  const [nickname, setNickname] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    const fetchFilmList = async () => {
      try {
        const token = localStorage.getItem('token');

        const getInfoPlayer = await AdminApi.get(`/u/${userId}/getinfoplayer`, {
          headers: {
            Authorization: `Bearer ${token}`
          }});

        const response = await AdminApi.get(`/u/uploadedby/${userId}`);
        //console.log(response.data)

			  setNickname(getInfoPlayer.data.nickname);
        setFilmList(response.data);
      } catch (error) {
        console.error('Errore durante il recupero delle transazioni:', error);

        switch (error.response.status) {
          case 401:
            navigate('/e/401');
            break;
          case 403:
            navigate('/e/403');
            break;
          case 500:
            navigate('/e/500');
            break;
          default:
            console.error('Errore durante il recupero delle transazioni:', error);
        }
      
      }
    };

    fetchFilmList();
  }, [userId, navigate]); // Re-fetch film list when userId changes

  return (
    <div className="container">
      <h2>Ultimi film caricati da {nickname}</h2>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th style={{ width: '10%' }}>ID</th>
            <th style={{ width: '30%' }}>Nome Film</th>
            <th style={{ width: '50%' }}>Immagine</th>
          </tr>
        </thead>
        <tbody>
          {filmList.map((film) => (
            <tr key={film.id}>
              <td>{film.id}</td>
              <td>{film.nome_film}</td>
              <td><img src={`${film.immagine}`} alt={`${film.nome_film}`} width="40%"></img></td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
}

export default FilmList;
