import React, { useEffect, useState } from 'react';
import AdminApi from '../../apis/AdminApi';
import ReCAPTCHA from 'react-google-recaptcha';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [captchaToken, setCaptchaToken] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [keyReCapatcha, setKeyRecapatcha] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setSubmitting(true); // Imposta lo stato del submit su true per evitare ulteriori invii
      const response = await AdminApi.post('/forgot-password', { email, captchaToken });
      setSuccessMessage(response.data.message);
    } catch (error) {
      setError(error.response.data.message);
    } finally {
      setSubmitting(false); // Resetta lo stato del submit a false
    }
  };

  const handleCaptchaChange = (token) => {
    setCaptchaToken(token);
  };

  useEffect(() => {
    const fetchData = async () => {

        calcolaKeyRecaptcha(process.env.REACT_APP_ENVIRONMENT);
        
    }

    fetchData();
  }, []);


  const calcolaKeyRecaptcha = (envinroment) => {
    
    switch (envinroment) {
      case 'test':
        setKeyRecapatcha(process.env.REACT_APP_RECAPTCHA_SITE_KEY_TEST);
        break;
      case 'production':
        setKeyRecapatcha(process.env.REACT_APP_RECAPTCHA_SITE_KEY_PRODUCTION);
        break;
      default:
        return null;

    }
  }

  return (
    <div className="container d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
      <div className="card text-center" style={{ width: '400px' }}>
        <div className="card-body">
          <h2 className="card-title">Recupero password</h2>
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label htmlFor="email" className="form-label">Email:</label>
              <input
                type="email"
                className="form-control"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            {error && <div className="text-danger mb-3">{error}</div>}
            {successMessage && <div className="text-success mb-3">{successMessage}</div>}
            <button type="submit" className="btn btn-info" disabled={!captchaToken || submitting}>Invia email di recupero</button>
            <p/>
            <ReCAPTCHA
              sitekey={keyReCapatcha}
              onChange={handleCaptchaChange}
            />
          </form>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
