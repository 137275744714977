import React, { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import Cookies from 'js-cookie';
import AdminApi from '../../apis/AdminApi';

function ClassificaPerpetua() {
  const [classifica, setClassifica] = useState([]);
  const userId = Cookies.get("user_id");

  useEffect(() => {
    const fetchClassifica = async () => {
      try {
        const response = await AdminApi.get(`/g/classificaperpetua`);
        const players = response.data;

        const updatedPlayers = await Promise.all(
          players.map(async (player) => {
            const cardResponse = await AdminApi.get(`/u/${player.id_utente}/get-distinct-cards`);
            const numInt = parseInt(cardResponse.data.n_attori, 10);

            return {
              ...player,
              distinctCards: numInt,
            };
          })
        );

        setClassifica(updatedPlayers);
      } catch (error) {
        console.error('Errore durante il recupero della lista film:', error);
      }
    };

    fetchClassifica();
  }, []);

  return (
    <div className="container text-center">
      <h2>🏆 Top 10 Globale 🏆</h2>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th style={{ width: '2%' }}>Posizione</th>
            <th style={{ width: '30%' }}>Giocatore</th>
            <th style={{ width: '18%' }}>Punteggio</th>
            <th style={{ width: '10%' }}>Oscar Vinti</th>
            <th style={{ width: '10%' }}>Razzie Vinti</th>
            <th style={{ width: '10%' }}>% Achievement</th>
            <th style={{ width: '10%' }}>% Distinct Cards</th>
          </tr>
        </thead>
        <tbody>
          {classifica.map((player, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{player.achievement >= 8 && <a href={`/u/${player.id_utente}/achievements`}>
                <img src="https://download.cjgames.it/repository/cinezor/img/achievements/completionist.svg" width="20px" alt="Award" /></a>}
                {userId && player.id_utente === parseInt(userId) ? (
                  <b><a className="bg-warning" href={`/u/${player.id_utente}`}>{player.nickname}</a></b>
                ) : (
                  <a href={`/u/${player.id_utente}`}> {player.nickname}</a>
                )}
              </td>
              <td>{player.score}</td>
              <td>{player.punti_oscar}</td>
              <td>{player.punti_razzie}</td>
              <td><a href={`/u/${player.id_utente}/achievements`}>{(player.achievement / 8) * 100}%</a></td>
              <td><a href={`/u/${player.id_utente}/collections`}>{((player.distinctCards / 150) * 100).toFixed(2)}%</a></td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
}

export default ClassificaPerpetua;
