import React, { useEffect, useState } from "react";
import "./css/navigation.css";
import AdminApi from "../apis/AdminApi";

const NavigationGuest = (  ) => {

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [appVersion, setAppVersion] = useState("");

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const getVersion = await AdminApi.get(`/dev-version`);
        setAppVersion(getVersion.data.versione);

        //console.log(getAward.data.valore)

      } catch (error) {
        // Gestione degli errori qui
        console.error('Errore durante il recupero dei dati:', error);
      }
    };

    fetchData(); // Chiamata alla funzione fetchData() al montaggio del componente

  }, [appVersion]); 

  // quando autenticato faccio una chiamata get anagrafica e mi ricavo tutti i dati, tanto il navigation sta sempre lì

  return (
    <nav className={`navbar navbar-expand-lg navbar-dark bg-dark ${isMenuOpen ? 'open-menu' : ''}`}>
      <div className="container-fluid">
        <a className="navbar-brand" href="/">
          cjgames.it
        </a>
        <button
          className="navbar-toggler"
          type="button"
          onClick={handleMenuToggle}
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className={`collapse navbar-collapse ${isMenuOpen ? 'show' : ''}`}>
          <ul className="navbar-nav">
          <li className="nav-item">
            <a className="nav-link version" href="/">
              (Versione {appVersion})
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/">
              🏠 Home
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/motd">
              🎬 Film del giorno
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="https://support.cjgames.it">
              📧 Assistenza
              </a>
            </li>
          </ul>
          <ul className="navbar-nav ml-auto">
          <li className="nav-item">
                <a href="/login" className="btn btn-danger">
                🔑 Login
                </a>
            </li>
            <li className="nav-item">
                <a href="/register" className="btn btn-danger" style={{marginLeft: "10px"}}>
                📝 Registrati
                </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default NavigationGuest;
