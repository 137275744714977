import React, { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import AdminApi from '../../apis/AdminApi'; // Supponiamo che AdminApi sia un'istanza di Axios o di un altro client per effettuare richieste HTTP
import Cookies from 'js-cookie';

function ClassificaMensile() {
  const [classifica, setClassifica] = useState([]);
  const userId = Cookies.get("user_id");

  useEffect(() => {
    const fetchClassifica = async () => {
      try {
        const response = await AdminApi.get(`/g/classifica`);
        //console.log(response.data)

        setClassifica(response.data);
      } catch (error) {
        console.error('Errore durante il recupero della lista film:', error);
      }
    };


  fetchClassifica();
  }, []); // Re-fetch film list when userId changes

  return (
    <div className="container text-center">
      <h2>🏆 Top 10 Mensile 🏆</h2>
      <Table striped bordered hover>
        <thead>
          <tr>
          <th style={{ width: '2%' }}>Posizione</th>
          <th style={{ width: '20%' }}>Giocatore</th>
            <th style={{ width: '18%' }}>Punteggio</th>
            <th style={{ width: '20%' }}>Oscar Vinti</th>
            <th style={{ width: '20%' }}>Razzie Vinti</th>
            <th style={{ width: '20%' }}>Grado</th>
          </tr>
        </thead>
        <tbody>
          {classifica.map((player, index) => (
            
            <tr key={index}>
              <td>{index+1}</td>
              <td>{player.achievement >= 8 && <a href={`/u/${player.id_utente}/achievements`}>
                <img src="https://download.cjgames.it/repository/cinezor/img/achievements/completionist.svg" width="20px" alt="Award" /></a>} 
                {userId && player.id_utente === parseInt(userId) ? (
                  <b><a className="bg-warning" href={`/u/${player.id_utente}`}>{player.nickname}</a></b>
                ) : (
                  <a href={`/u/${player.id_utente}`}> {player.nickname}</a>
                )}
              </td>
              <td>{player.punteggio}</td>
              <td>{player.punti_oscar}</td>
              <td>{player.punti_razzie}</td>
              <td>{player.rank}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
    
  );
}

export default ClassificaMensile;
