import React from "react";

const Err500 = () => {
  return (
    <div>
      <h3 className="font-weight-light text-center">
        500 Internal Server Error
      </h3>
    </div>
  );
};

export default Err500;
