import React, { useEffect, useState } from "react";
import TmbdApi from "../../apis/TmbdApi";
import AdminApi from "../../apis/AdminApi";
import RatingStar from "./RatingStar";

const FilmDelGiorno = () => {
  const apiKey = process.env.REACT_APP_TMDB_API_KEY;
  const [motd, setMotd] = useState([]);


  // questa logica verrà sostituita con un numero generato a db giornalmente
  //const randomPage = Math.floor(Math.random() * 30) + 1;
  //const randomMovies = Math.floor(Math.random() * 18) + 1;

  /*
    const params = {
    query: 'Dune',
    include_adult: false,
    language: 'en-EN',
    page: 1
    };

    const config = {
    headers: {
        accept: 'application/json'
    },
    params: params
    };
    

    useEffect(() => {
      const fetchData = async () => {
        try {
          const res = await TmbdApi.get(`/search/movie?api_key=${apiKey}`, config);
          setMotd(res.data.results.slice(0, 2));
        } catch (error) {
          console.log("Errore durante il recupero dei dati:", error);
        }
      };
  
      fetchData();
    }, []);
  
    */
  useEffect(() => {
    const fetchData = async () => {
      try {
        const resMotd = await AdminApi.get('/getmotd');
        const { random_page, random_movie } = resMotd.data;

        const res = await TmbdApi.get(
          `/discover/movie?include_adult=false&include_video=true&original_language=en&language=it-IT&page=${random_page}&sort_by=popularity.desc&api_key=${apiKey}`
        );
        setMotd(res.data.results.slice(random_movie, random_movie + 2));
      } catch (error) {
        console.log("Errore durante il recupero dei dati:", error);
      }
    };

    fetchData();
  }, [apiKey]);

  return (
    <div className="container">
      <div className="row">
        {motd.map((film, index) => (
          <div
            key={index}
            className="col-md-6 d-flex flex-column align-items-center"
          >
            <h2>Movie of the day {index + 1}</h2>
            <table className="table table-bordered">
              <tbody>
                <tr>
                  <td>Poster:</td>
                  <td>
                    <div
                      className="image-container"
                      style={{
                        paddingTop: "10px",
                        paddingBottom: "10px",
                        textAlign: "center",
                      }}
                    >
                      <img
                        src={`https://image.tmdb.org/t/p/w500${film.poster_path}`}
                        alt="Poster"
                        style={{
                          maxWidth: "80%",
                          height: "auto",
                          width: "auto",
                        }}
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>Original Language:</td>
                  <td>{film.original_language}</td>
                </tr>
                <tr>
                  <td>Title:</td>
                  <td>{film.title}</td>
                </tr>
                <tr>
                  <td>Original Title:</td>
                  <td>{film.original_title}</td>
                </tr>
                <tr>
                  <td>Valutazione:</td>
                  <td>
                    <RatingStar rating={film.vote_average} /> (
                    <b>{film.vote_average})</b>
                  </td>
                </tr>
                <tr>
                  <td>Descrizione:</td>
                  <td>{film.overview}</td>
                </tr>
                {/* Aggiungi altre righe per le proprietà che vuoi visualizzare */}
              </tbody>
            </table>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FilmDelGiorno;
