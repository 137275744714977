import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

const SortableItem3 = ({ id, src, title, image }) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    padding: '8px',
    margin: '4px',
    backgroundColor: isDragging ? '#e0e0e0' : '#f0f0f0',
    border: '1px solid #ccc',
    borderRadius: '4px',
    cursor: 'grab',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100px',
    minWidth: '100px',
  };

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      {src ? (
        <img src={src} alt="" style={{ maxWidth: '100px', maxHeight: '100px' }} />
      ) : (
        <div>
          <p>{title}</p>
          {image && <img src={image.src} alt="" style={{ maxWidth: '100px', maxHeight: '100px' }} />}
        </div>
      )}
    </div>
  );
};

export default SortableItem3;
