import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import FilmFinder from "../apis/FilmFinder";
import oscarImg from "../images/oscar.png";
import razzieImg from "../images/razzie.png";
import AudioPlayer from "./media/AudioPlayer";
import AudioBellaCj from "./media/audio/bellacj.mp3";
import AudioNabbo from "./media/audio/chenabbo.mp3";
import RisultatiQuiz from "./quiz/RisultatiQuiz";
import AdminApi from "../apis/AdminApi";


const FineQuiz = () => {
  const { id_sessione } = useParams();
  const [punteggio, setPunteggioSessione] = useState("");
  const [oscar, setOscarSessione] = useState("");
  const [razzie, setRazzieSessione] = useState("");
  const [inputCheat, setInputCheat] = useState("");
  const [mostraCheat, setMostraCheat] = useState(false);
  const [tappoRank, setTappoRank] = useState("");
  const [tappoNameRank, setTappoNameRank] = useState("");
  const [isQuizEnded, setQuizEnded] = useState("");

  const [modalita, setModalita] = useState("");
  const [categoria, setCategoria] = useState("");
  const [aiuti, setAiuti] = useState("");
  const [tempoUsato, setTempoUsato] = useState("");
  const [punteggioTempo, setPunteggioTempo] = useState("");

  const urlOscar = oscarImg;
  const urlRazzie = razzieImg;

  const navigate = useNavigate();

  function convertiSecondiInTempo(secondi) {
    const ore = Math.floor(secondi / 3600);
    const minuti = Math.floor((secondi % 3600) / 60);
    const secondiRimasti = secondi % 60;
  
    const formatoOre = String(ore).padStart(2, '0');
    const formatoMinuti = String(minuti).padStart(2, '0');
    const formatoSecondi = String(secondiRimasti).padStart(2, '0');
  
    return `${formatoOre}:${formatoMinuti}:${formatoSecondi}`;
  }

  useEffect(() => {
    const token = localStorage.getItem('token');

    if (!token) {
      navigate("/login");
    }

    try {
      const fetchData = async () => {
        const isQuizEnded = await AdminApi.get(
          `/api/v1/quiz/isquizended/${id_sessione}`
        );
        setQuizEnded(isQuizEnded.data.result.check_sessione_conclusa);

        if (isQuizEnded.data.result.check_sessione_conclusa === 0) {
          return;
        }

        // scelgo la soluzione del film per quella sessione
        const res = await FilmFinder.get(
          `/proc/calcolapunteggio/${id_sessione}`
        );

        setModalita(res.data.result.descr_modalita);
        setCategoria(res.data.result.descr_categoria);
        setPunteggioSessione(res.data.result.punteggio);
        setOscarSessione(res.data.result.punti_oscar);
        setRazzieSessione(res.data.result.punti_razzie);
        setAiuti(res.data.result.n_aiuti_usati);
        setTempoUsato(res.data.result.n_secondi_risposta);
        setPunteggioTempo(res.data.result.punteggio_tempo)

        if (res.data.result.punti_oscar >= 8) {
          setTappoRank("bellacj");
          setTappoNameRank("Graaaande CJ");
        } else {
          setTappoRank("sononabbo");
          setTappoNameRank("Sono nabbo PD");
        }
      };

      fetchData();
    } catch (error) {
      console.log("errore");
    }
  }, [id_sessione, navigate]);

  const arrOscars = [];
  for (let i = 0; i < oscar; i++) {
    arrOscars.push(
      <img
        key={i}
        src={urlOscar}
        alt={`Oscar ${i + 1}`}
        width="81px"
      />
    );
  }

  //console.log(arrOscars.length)

  const arrRazzies = [];
  for (let i = 0; i < razzie; i++) {
    arrRazzies.push(
      <img
        key={i}
        src={urlRazzie}
        alt={`Razzie ${i + 1}`}
        width="65px"
      />
    );
  }

  const handleCheat = (e) => {
    setInputCheat(e.target.value);
    // Verifica se il valore inserito corrisponde alla sequenza desiderata
    if (e.target.value === "borghin") {
      setMostraCheat(true);
    } else {
      setMostraCheat(false);
    }
  };

  return (
    <div>
      {isQuizEnded === 1 && (
        <div className="container">
          {/* Prima riga */}
          <div className="row" style={{ background: "#f0f0f0" }}>
            <div
              className="col d-flex justify-content-center align-items-center"
              style={{ border: "1px solid #ccc" }}
            >
              <div>
                <p></p>
                <p>Tipo Quiz </p>
              </div>
            </div>
            <div
              className="col d-flex justify-content-center align-items-center"
              style={{ border: "1px solid #ccc" }}
            >
              <div>
                <p></p>
                <p>
                  <b> {modalita} - {categoria} </b>
                </p>
              </div>
            </div>
          </div>
          <div className="row" style={{ background: "#f0f0f0" }}>
            <div
              className="col d-flex justify-content-center align-items-center"
              style={{ border: "1px solid #ccc" }}
            >
              <div>
                <p></p>
                <p>Punteggio</p>
              </div>
            </div>
            <div
              className="col d-flex justify-content-center align-items-center"
              style={{ border: "1px solid #ccc" }}
            >
              <div>
                <p></p>
                <p>
                  Hai ottenuto un totale di <b>{punteggio}</b> per la classifica
                  totale
                </p>
              </div>
            </div>
          </div>

          {/* Seconda riga con sfondo alternato */}
          <div className="row" style={{ background: "#e0e0e0" }}>
            <div
              className="col d-flex justify-content-center align-items-center"
              style={{ border: "1px solid #ccc" }}
            >
              <div>
                <p></p>
                <p> {arrOscars} </p>
              </div>
            </div>
            <div
              className="col d-flex justify-content-center align-items-center"
              style={{ border: "1px solid #ccc" }}
            >
              <div>
                <p></p>
                <p>
                  Hai vinto <b>{oscar}</b> oscar! <br/>(+{oscar*10} punti)
                </p>
              </div>
            </div>
          </div>

          {/* Terza riga */}
          <div className="row" style={{ background: "#f0f0f0" }}>
            <div
              className="col d-flex justify-content-center align-items-center"
              style={{ border: "1px solid #ccc" }}
            >
              <div>
                <p></p>
                <p> {arrRazzies} </p>
              </div>
            </div>
            <div
              className="col d-flex justify-content-center align-items-center"
              style={{ border: "1px solid #ccc" }}
            >
              <div>
                <p></p>
                <p>
                  Ti sei guadagnato <b> {razzie} </b> razzie awards
                </p>
              </div>
            </div>
          </div>

          <div className="row" style={{ background: "#f0f0f0" }}>
            <div
              className="col d-flex justify-content-center align-items-center"
              style={{ border: "1px solid #ccc" }}
            >
              <div>
                <p></p>
                <p> Aiuti usati </p>
              </div>
            </div>
            <div
              className="col d-flex justify-content-center align-items-center"
              style={{ border: "1px solid #ccc" }}
            >
              <div>
                <p></p>
                <p>
                  <b>{aiuti}</b> aiuti usati <br/>(+{(5 - aiuti)*5} punti)
                </p>
              </div>
            </div>
          </div>

          <div className="row" style={{ background: "#f0f0f0" }}>
            <div
              className="col d-flex justify-content-center align-items-center"
              style={{ border: "1px solid #ccc" }}
            >
              <div>
                <p></p>
                <p> Tempo impiegato </p>
              </div>
            </div>
            <div
              className="col d-flex justify-content-center align-items-center"
              style={{ border: "1px solid #ccc" }}
            >
              <div>
                <p></p>
                <p>
                  <b>{convertiSecondiInTempo(tempoUsato)} ({Math.round(tempoUsato/120*100)}%)</b> <br/>(+{punteggioTempo} punti)
                </p>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Solo in caso di cheat */}

      <div className="container">
        {/* Prima riga */}
        {mostraCheat && (
          <div className="row" style={{ background: "#f0f0f0" }}>
            <div
              className="col d-flex justify-content-center align-items-center"
              style={{ border: "1px solid #ccc" }}
            >
              <div>
                <p></p>
                <p>
                  <a href="/#">Il tuo Tappo Rank: </a>
                  <br></br>
                  <b>{tappoNameRank}</b>
                </p>
              </div>
            </div>
            <div
              className="col d-flex justify-content-center align-items-center"
              style={{ border: "1px solid #ccc" }}
            >
              <div>
                {/* Div che mostra una cosa per un valore e un'altra cosa per un altro valore */}
                {tappoRank === "bellacj" && (
                  <div>
                    <AudioPlayer src={AudioBellaCj} />
                  </div>
                )}

                {tappoRank === "sononabbo" && (
                  <div>
                    <AudioPlayer src={AudioNabbo} />
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="container">
        <RisultatiQuiz />
      </div>

      <div className="container">
        <input
          type="text"
          value={inputCheat}
          onChange={handleCheat}
          placeholder="Secret key"
        />

        {/* Controlla se il div deve essere mostrato */}
        {mostraCheat && <div>.. beato ti che non te capissi un casso</div>}
      </div>

      <div className="container">
        {/* Prima riga */}
        <div className="row" style={{ background: "#f0f0f0" }}>
          <div
            className="col d-flex justify-content-center align-items-center"
            style={{ border: "1px solid #ccc" }}
          >
            <div>
              <p></p>
              <a
                href="/"
                className="btn btn-primary btn-lg active"
                role="button"
              >
                Nuovo quiz
              </a>
              <p></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FineQuiz;
