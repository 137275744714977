import React, { useState, useEffect } from "react";
import AdminApi from "../apis/AdminApi";
import { useNavigate } from "react-router-dom";
import { PacmanLoader } from "react-spinners";

const WithAuth = ({ children }) => {
  const [authenticated, setAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    const checkAuthentication = async () => {
      const token = localStorage.getItem('token');
    
      if (token) {
        try {
          const response = await AdminApi.get("/protected", {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
    
          //console.log(response);
    
          if (response.status === 200) {
            setAuthenticated(true);
          } else {
            setAuthenticated(false); // Aggiungi questo per gestire il caso in cui la richiesta non restituisce uno status 200
          }
        } catch (error) {
          console.error(
            "Errore durante la verifica dell'autenticazione:",
            error
          );
          setAuthenticated(false); // Aggiungi questo per gestire il caso in cui la richiesta fallisce
        }
      } else {
        setAuthenticated(false); // Assicurati che authenticated sia impostato su false se non c'è un token
      }

      //console.log(authenticated);
      
    };
    

    const timeout = setTimeout(() => {
      setLoading(false);
    }, 1000);

    checkAuthentication();

    return () => clearTimeout(timeout);
  }, [authenticated]);

  useEffect(() => {
    if (!authenticated && !loading) {
      navigate(`/login`);
    }
  }, [authenticated, loading, navigate]);

  if (loading) {
    return (
      <div>
        <div className="d-flex justify-content-center">
          <PacmanLoader color="#808080" size={50} />
        </div>
        <div className="text-center">
          <p></p>
          <div className="mt-2"></div>
        </div>
        <div className="text-center">
          <p>Loading ...</p>
          <div className="mt-2"></div>
        </div>
      </div>
    );
  }

  return <>{children({ authenticated })}</>;
};

export default WithAuth;
