import React from 'react';

const AudioPlayer = ({ src }) => {
  return (
    <div>
      {/* Aggiungi l'elemento audio con il file audio */}
      <audio controls>
        <source src={src} type="audio/mpeg" />
        Il tuo browser non supporta la riproduzione audio.
      </audio>
    </div>
  );
};

export default AudioPlayer;
