import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import LoadingTimer from "./spinners/LoadingTimer";

const OldTimer = ({ children }) => {
  const [authenticated, setAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    const checkAuthentication = async () => {
    
      setAuthenticated(true);
      
    };

    const timeout = setTimeout(() => {
      setLoading(false);
    }, 4700);

    checkAuthentication();

    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    if (!authenticated && !loading) {
      navigate(`/login`);
    }
  }, [authenticated, loading, navigate]);

  if (loading) {
    return (
      <div>
        <div className="d-flex justify-content-center">
          <LoadingTimer/>
        </div>
        <div className="text-center">
          <p></p>
          <div className="mt-2"></div>
        </div>
        <div className="text-center">
          <p>Loading ...</p>
          <div className="mt-2"></div>
        </div>
      </div>
    );
  }

  return <>{children({ authenticated })}</>;
};

export default OldTimer;
