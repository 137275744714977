//rafce
import React from "react";
import CercaFilm from "../../components/admin/CercaFilm";
import WithAuth from "../../components/WithAuth";

const CercaFilmP = () => {
  return (
    <div className="container">
      <WithAuth>
        {({ authenticated }) => (authenticated ? <CercaFilm /> : null)}
      </WithAuth>
    </div>
  );
};

export default CercaFilmP;
