import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom'; // Importa useParams per ottenere i parametri dall'URL
import { Table } from 'react-bootstrap';
import AdminApi from '../../apis/AdminApi'; // Supponiamo che AdminApi sia un'istanza di Axios o di un altro client per effettuare richieste HTTP

function LastSessions() {
  const { userId } = useParams(); // Ottieni l'ID utente dalla URL
  const [sessions, setSessions] = useState([]);
  const [infoPlayer, setInfoPlayer] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    const fetchFilmList = async () => {
      try {
        const token = localStorage.getItem('token');

        const getInfoPlayer = await AdminApi.get(`/u/${userId}/getinfoplayer`, {
          headers: {
            Authorization: `Bearer ${token}`
          }});

        const response = await AdminApi.get(`/u/${userId}/sessions`);
        //console.log(response.data)

        setInfoPlayer(getInfoPlayer.data);
        setSessions(response.data);

      } catch (error) {
        switch (error.response.status) {
          case 401:
            navigate('/e/401');
            break;
          case 403:
            navigate('/e/403');
            break;
          case 500:
            navigate('/e/500');
            break;
          default:
            console.error('Errore durante il recupero delle transazioni:', error);
        }
      }
    };

    fetchFilmList();
  }, [userId, navigate]); // Re-fetch film list when userId changes

  return (
    <div className="container">
      <h2>Le ultime sessioni di {infoPlayer.nickname}</h2>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th style={{ width: '50%' }}>Sessione</th>
            <th style={{ width: '10%' }}>Punteggio</th>
            <th style={{ width: '40%' }}>Data quiz</th>
          </tr>
        </thead>
        <tbody>
          {sessions.map((session,index) => (
            <tr key={index}>
              <td><a href={`/quiz/${userId}/${session.sessione}/fine`}>{session.descr_modalita} - {session.descr_categoria}</a></td>
              <td>{session.punteggio}</td>
              <td>{session.data_inizio_sessione}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
}

export default LastSessions;
