import Cookies from 'js-cookie';
import React from 'react';

const Features = () => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-md-4 mb-4">
          <div className="card align-items-center">
            <img src="https://download.cjgames.it/repository/cinezor/img/cards/achievement-trophy-surrounded-by-stars-clip-art.jpg" className="card-img-top w-50" alt="Placeholder" />
            <div className="card-body">
              <h5 className="card-title">🏆 Achievement 🏆</h5>
              <p className="card-text">Lista di tutti gli achievement conquistati</p>
              <a href={`/u/${Cookies.get("user_id")}/achievements`} className="btn btn-primary">Vedi Achievement</a>
            </div>
          </div>
        </div>
        <div className="col-md-4 mb-4">
          <div className="card align-items-center">
            <img src="https://download.cjgames.it/repository/cinezor/img/cards/leaderboard.png" className="card-img-top w-50" alt="Placeholder" />
            <div className="card-body">
              <h5 className="card-title">🎖️ Leaderboard 🎖️</h5>
              <p className="card-text ">Top Player</p>
              <a href="/g/leaderboard" className="btn btn-primary">Vedi Classifiche</a>
            </div>
          </div>
        </div>
        <div className="col-md-4 mb-4">
          <div className="card align-items-center">
            <img src="https://download.cjgames.it/repository/cinezor/img/cards/poster.png" className="card-img-top w-50" alt="Placeholder" />
            <div className="card-body">
              <h5 className="card-title">🎬 Film del giorno 🎬</h5>
              <p className="card-text">Scopri i nuovi film del giorno</p>
              <a href="/motd" className="btn btn-primary">Vedi film</a>
            </div>
          </div>
        </div>
        <div className="col-md-4 mb-4">
          <div className="card align-items-center">
            <img src="https://download.cjgames.it/repository/cinezor/img/cards/upload.png" className="card-img-top w-50" alt="Placeholder" />
            <div className="card-body">
              <h5 className="card-title">🎥 Carica un film 🎥</h5>
              <p className="card-text">Carica il tuo film preferito in Cinezor!</p>
              <a href="/admin/cerca-film" className="btn btn-primary">Carica Ora</a>
            </div>
          </div>
        </div>
        <div className="col-md-4 mb-4">
          <div className="card align-items-center">
            <img src="https://download.cjgames.it/repository/cinezor/img/cards/archive.png" className="card-img-top w-50" alt="Placeholder" />
            <div className="card-body">
              <h5 className="card-title">📝 Registro Partite 📝</h5>
              <p className="card-text">Consulta le tue ultime prestazioni</p>
              <a href={`/u/${Cookies.get("user_id")}/sessions`} className="btn btn-primary">Guarda ora</a>
            </div>
          </div>
        </div>
        <div className="col-md-4 mb-4">
          <div className="card align-items-center">
            <img src="https://download.cjgames.it/repository/cinezor/img/cards/award.png" className="card-img-top w-50" alt="Placeholder" />
            <div className="card-body">
              <h5 className="card-title">🍿 FantaOscar🍿</h5>
              <p className="card-text">Colleziona le carte degli attori</p>
              <a href={`/u/${Cookies.get("user_id")}/collections`} className="btn btn-primary">Guarda Collezione</a>
            </div>
          </div>
        </div>
        <div className="col-md-4 mb-4">
          <div className="card align-items-center">
            <img src="https://download.cjgames.it/repository/cinezor/img/cards/chest.png" className="card-img-top w-50" alt="Placeholder" />
            <div className="card-body">
              <h5 className="card-title">💎 Riscatta premi 💎</h5>
              <p className="card-text">Riscatta i premi giornalieri</p>
              <a href={`/u/${Cookies.get("user_id")}/getnewcard`} className="btn btn-primary">Riscatta ora!</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Features;
